const SITE_TITLE = 'INCF Japan Node';
const SITE_SLOGAN = 'Top';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-2365647-1';
const XOONIPS_ITEMTYPES = ['journal', 'article', 'generaldata'];
const PICO_MODULES = ['aboutus', 'aini', 'hackathon', 'joinus', 'link', 'nbni', 'program', 'publication', 'resource', 'tutorial'];
const BULLETIN_MODULES = ['news'];
export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
    PICO_MODULES,
    BULLETIN_MODULES,
};

export default Config;