import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import ItemUtil from './lib/ItemUtil';
import styles from './Xoonips.module.css';
import XoonipsAdvancedSearch from './XoonipsAdvancedSearch';
import XoonipsDetailItem from './XoonipsDetailItem';
import XoonipsSearchByAdvancedKeyword from './XoonipsSearchByAdvancedKeyword';
import XoonipsSearchByIndexId from './XoonipsSearchByIndexId';
import XoonipsSearchByItemType from './XoonipsSearchByItemType';
import XoonipsSearchByKeyword from './XoonipsSearchByKeyword';
import XoonipsTop from './XoonipsTop';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

const Xoonips: React.FC<Props> = (props: Props) => {
    const { lang, location } = props;
    const query = new URLSearchParams(location.search);
    query.delete('ml_lang');
    return (
        <div className={`xoonips ${styles.database}`}>
            <Helmet>
                <title>{Functions.mlang('[en]Database[/en][ja]データベース[/ja]', lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <Switch>
                <Route exact path="/modules/xoonips" render={(props: RouteComponentProps) => <XoonipsTop lang={lang} {...props} />} />
                <Route exact path="/modules/xoonips/index.php" render={() => <Redirect to="/modules/xoonips" />} />
                <Route exact path="/modules/xoonips/detail.php" render={() => {
                    const idStr = query.get('item_id');
                    const doi = query.get('id');
                    const id = idStr !== null && /^\d+$/.test(idStr) ? parseInt(idStr, 10) : null;
                    return <XoonipsDetailItem lang={lang} id={id} doi={doi} />;
                }} />
                <Route exact path="/modules/xoonips/list.php" render={() => {
                    const idStr = query.get('index_id');
                    const id = idStr !== null && /^\d+$/.test(idStr) ? parseInt(idStr, 10) : null;
                    return <XoonipsSearchByIndexId lang={lang} id={id} />;
                }} />
                <Route exact path="/modules/xoonips/search.php" render={() => {
                    const op = query.get('op') || '';
                    query.delete('op');
                    switch (op) {
                        case 'quick': {
                            const { type, keyword } = ItemUtil.getSearchKeywordByQuery(location.search);
                            return <XoonipsSearchByKeyword lang={lang} type={type} keyword={keyword} />
                        }
                        case 'advanced': {
                            const search_itemtype = query.get('search_itemtype');
                            if (search_itemtype !== null) {
                                return <XoonipsSearchByItemType lang={lang} itemType={search_itemtype} itemSubType="" />
                            }
                            if (Array.from(query).length > 0) {
                                return <XoonipsSearchByAdvancedKeyword {...props} />
                            }
                            return <XoonipsAdvancedSearch {...props} />
                        }
                    }
                    return <PageNotFound lang={lang} />;
                }} />
                <Route component={PageNotFound} />
            </Switch>
        </div>
    );
}

export default withRouter(Xoonips);