import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../../common/lib/PageNotFound';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import BulletinContext from './BulletinContext';
import BulletinUtils from './BulletinUtils';

interface Props {
    lang: MultiLang;
    context: BulletinContext;
    year: number;
    month: number;
}

const BulletinArchive: React.FC<Props> = (props: Props) => {
    const { lang, context, year, month } = props;
    const years = context.getArchiveYearRange();
    if (years.min === 0 || years.max === 0) {
        return <PageNotFound lang={lang} />;
    }
    const module = context.getModule();
    if (module === null) {
        return <PageNotFound lang={lang} />;
    }
    const title = Functions.mlang('[en]Archive[/en][ja]アーカイブ[/ja]', lang);
    const titles = [Functions.siteTitle(lang), Functions.mlang(module.name, lang), title];
    const stories = year !== 0 ? context.getStoriesByMonth(year, month) : [];
    return <div className="arcive">
        <Helmet>
            <title>{titles.reverse().join(' - ')}</title>
        </Helmet>
        <div style={{ float: 'right' }}>|&nbsp;<Link to={context.getArchiveUrl()}>{Functions.mlang('[en]Archive[/en][ja]アーカイブ[/ja]', lang)}</Link>&nbsp;|</div>
        <div style={{ clear: 'both', height: '0px', visibility: 'hidden' }}></div>
        <div>
            <table>
                <tbody>
                    <tr>
                        <th colSpan={13}>{Functions.mlang('[en]News archive[/en][ja]ニュースアーカイブ[/ja]', lang)}</th>
                    </tr>
                    {[...Array(years.max - years.min + 1)].map((v, index) => {
                        const _year = index + years.min;
                        const evenodd = index % 2 === 0 ? 'even' : 'edd';
                        return <tr key={_year}>
                            <th>{_year}{lang === 'ja' && '年'}</th>
                            {[...Array(12)].map((v, index) => {
                                const _month = index + 1;
                                const label = BulletinUtils.getMonthLabel(_month, lang);
                                const style: React.CSSProperties = { textAlign: 'center' };
                                const num = context.getArchiveCounter(_year, _month);
                                const xmonth = (num === 0 || (_year === year && _month === month)) ? label : <Link to={context.getArchiveUrl(_year, _month)} style={{textDecoration: 'underline'}}>{label}</Link>;
                                if (_year === year && _month === month) {
                                    style['backgroundColor'] = '#ffcccc';
                                    style['color'] = '#0000ff';
                                    style['fontWeight'] = 'bold';
                                }
                                return <td key={index} className={evenodd} style={style}>{xmonth}</td>;
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        {stories.length > 0 &&
            <div className="item">
                <div className="itemHead">
                    <span className="itemTitle">
                        {Functions.mlang('[en]News[/en][ja]ニュース[/ja]', lang)}
                    </span>
                </div>
                <div className="itemInfo">
                    <ul style={{ textAlign: 'left' }}>
                        {stories.map((story, index) => {
                            const topic = context.getTopic(story.topic_id);
                            return <li key={story.id}>
                                <Link to={context.getTopicUrl(story.topic_id, 5, 0)}>{Functions.mlang(topic === null ? '' : topic.title, lang)}</Link>
                                &nbsp;:&nbsp;
                                <Link to={context.getStoryUrl(story.id)}>{Functions.mlang(story.title, lang)}</Link> ({story.posttime})
                            </li>
                        })}
                    </ul>
                </div>
                <div>
                    {Functions.mlang('[en]There are[/en][ja]計[/ja] ' + stories.length + ' [en]story(ies) in total.[/en][ja]件のニュース記事があります[/ja]', lang)}
                </div>
            </div>
        }
    </div>;
};

export default BulletinArchive;