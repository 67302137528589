import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { Modal } from 'react-overlays';
import { RouteComponentProps, withRouter } from 'react-router';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';
import ItemUtil, { ItemBasicFile } from '../../../lib/ItemUtil';
import DateTime from './DateTime';
import FileSize from './FileSize';
import styles from './LicenseAgreementDialog.module.css';
import Rights from './Rights';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    file: ItemBasicFile;
    rights: string;
    useCc: number;
    ccCommercialUse: number;
    ccModification: number;
    show: boolean;
    unsetShow: () => void;
}

interface State {
    show: boolean;
    disabled: boolean;
}

class LicenseAgreementDialog extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            show: props.show,
            disabled: false,
        };
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleClickDownload = this.handleClickDownload.bind(this);
        this.handleClickCancel = this.handleClickCancel.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        if (nextProps.show && !prevState.show) {
            return { disabled: true, show: nextProps.show };
        }
        return null;
    }

    handleChangeCheckbox(e: ChangeEvent<HTMLInputElement>) {
        const disabled = e.target.value === '0';
        this.setState({ disabled });
    }

    handleClickDownload(e: MouseEvent<HTMLButtonElement>) {
        this.props.unsetShow();
        this.setState({ show: false });
    }

    handleClickCancel() {
        this.props.unsetShow();
        this.setState({ show: false });
    }

    renderBackdrop(props: any) {
        return <div className={styles.overlay} {...props} />;
    }

    render() {
        const { lang } = this.props;
        const url = ItemUtil.getFileUrl(this.props.file);
        return (
            <Modal className={styles.dialog} show={this.state.show} onHide={this.handleClickCancel} renderBackdrop={this.renderBackdrop}>
                <div>
                    <div>
                        {Functions.mlang('[en]Download file information[/en][ja]ダウンロードするファイルの情報[/ja]', lang)}
                        <div className={styles.box}>
                            {this.props.file.original_file_name}<br />
                            <table>
                                <tbody>
                                    <tr><td>Type</td><td>: {this.props.file.mime_type}</td></tr>
                                    <tr><td>Size</td><td>: <FileSize lang={lang} size={this.props.file.file_size} /></td></tr>
                                    <tr><td>Last updated</td><td>: <DateTime lang={lang} date={this.props.file.timestamp} onlyDate={true} /></td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br />
                    <div>
                        {Functions.mlang('[en]License agreement[/en][ja]ファイルのライセンス[/ja]', lang)}
                        <div className={styles.box}>
                            {Functions.mlang('[en]Please read the following license agreement carefully.[/en][ja]このファイルには下記のライセンスが設定されています。[/ja]', lang)}
                            <div>
                                <Rights lang={lang} rights={this.props.rights} useCc={this.props.useCc} ccCommercialUse={this.props.ccCommercialUse} ccModification={this.props.ccModification} />
                                <input type="radio" name="radio_license" value="1" onChange={this.handleChangeCheckbox} checked={!this.state.disabled} />{Functions.mlang('[en]I accept the terms in the license agreement.[/en][ja]ライセンスに同意します。[/ja]', lang)}<br />
                                <input type="radio" name="radio_license" value="0" onChange={this.handleChangeCheckbox} checked={this.state.disabled} />{Functions.mlang('[en]I do not accept the terms in the license agreement.[/en][ja]ライセンスに同意しません。[/ja]', lang)}<br />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className={styles.download}>
                        Acceptance is needed to download this file.
                        <br />
                        <a href={url} download={this.props.file.original_file_name}>
                            <button className="formButton" onClick={this.handleClickDownload} disabled={this.state.disabled}>Download</button>
                        </a>
                        <button className="formButton" onClick={this.handleClickCancel}>Cancel</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withRouter(LicenseAgreementDialog);