import React, { Component } from 'react';
import { MultiLang } from '../config';
import Functions from '../functions';
import ItemUtil, { SearchCallbackFunc, SortCondition } from './lib/ItemUtil';
import XoonipsListItem from './lib/XoonipsListItem';

interface Props {
    lang: MultiLang;
    itemType: string | null;
    itemSubType: string | null;
}

class XoonipsSearchByItemType extends Component<Props> {

    constructor(props: Props) {
        super(props);
        this.searchFunc = this.searchFunc.bind(this);
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        const { itemType, itemSubType } = this.props;
        if (itemType === null || itemSubType === null) {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByItemType(itemType, itemSubType, condition, func);
        }
    }

    getUrl() {
        const { itemType, itemSubType } = this.props;
        return ItemUtil.getItemTypeSearchUrl(itemType, itemSubType);
    }

    render() {
        const { lang } = this.props;
        const baseUrl = this.getUrl();
        return (
            <div className="list">
                <h3>{Functions.mlang('[en]Listing item[/en][ja]アイテム一覧[/ja]', lang)}</h3>
                <XoonipsListItem lang={lang} url={baseUrl} search={this.searchFunc} />
            </div>
        );
    }
}

export default XoonipsSearchByItemType;
