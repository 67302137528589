import React from 'react';
import Functions from '../../../functions';
import { ItemArticle } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import ArticleUtil from './ArticleUtil';

class ArticleDetail extends DetailBase {

    renderAuthor(item: ItemArticle) {
        const { lang } = this.props;
        return <table>
            <tbody>
                {item.author.map((author) => {
                    const name = author.replace(/(?:\(\d+\))+$/g, '');
                    const num = author === name ? '' : author.replace(/^.+?((?:\(\d+\))+)$/, '$1').replace(/\)\(/g, ', ').replace(/[()]/g, '');
                    return <tr key={author}><td>{Functions.mlang(name, lang)}{num !== '' && <span style={{ verticalAlign: 'super', fontSize: '70%' }}> {num}</span>}</td></tr>;
                })}
            </tbody>
        </table>;
    }

    renderAffiliation(item: ItemArticle) {
        const { lang } = this.props;
        return <table>
            <tbody>
                {item.affiliation.map((affiliation) => {
                    const name = affiliation.replace(/^(?:\(\d+\))+/g, '');
                    const num = affiliation === name ? '' : affiliation.replace(/^((?:\(\d+\))+).+?$/, '$1').replace(/\)\(/g, ', ').replace(/[()]/g, '');
                    return <tr key={affiliation}><td>{num !== '' && <span style={{ verticalAlign: 'super', fontSize: '70%' }}>{num} </span>}{Functions.mlang(name, lang)}</td></tr>;
                })}
            </tbody>
        </table>;
    }

    renderJournal(item: ItemArticle) {
        const { lang } = this.props;
        return <table>
            <tbody>
                <tr>
                    <td>{Functions.mlang('[en]Name[/en][ja]名称[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.journal_name, lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Volume[/en][ja]巻[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.journal_volume, lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Publication Year[/en][ja]発行年[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.publication_year.toString(), lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Publication Month[/en][ja]発行月[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.publication_month.toString(), lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Page[/en][ja]ページ[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.journal_page, lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Publisher[/en][ja]出版者[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.journal_publisher, lang)}</td>
                </tr>
            </tbody>
        </table>;
    }

    renderMeeting(item: ItemArticle) {
        const { lang } = this.props;
        return <table>
            <tbody>
                <tr>
                    <td>{Functions.mlang('[en]Name[/en][ja]名称[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.meeting_name, lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Place[/en][ja]場所[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.meeting_place, lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Date (From)[/en][ja]日付 (自)[/ja]', lang)}</td>
                    <td>: <ItemTypeField.DateTime lang={lang} date={item.meeting_date_from} onlyDate={true} /></td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Date (To)[/en][ja]日付 (至)[/ja]', lang)}</td>
                    <td>: <ItemTypeField.DateTime lang={lang} date={item.meeting_date_to} onlyDate={true} /></td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Program Num[/en][ja]発表番号[/ja]', lang)}</td>
                    <td>: {Functions.mlang(item.meeting_program_no, lang)}</td>
                </tr>
            </tbody>
        </table>;
    }

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemArticle;
        const doi = ArticleUtil.getDOI(item.doi);
        const fields = [
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: 'ID', value: doi !== '' ? <a href={`https://doi.org/${doi}`}>doi:{doi}</a> : item.doi },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[en]Author[/en][ja]著者[/ja]', value: this.renderAuthor(item) },
            { label: '[en]Affiliation[/en][ja]所属[/ja]', value: this.renderAffiliation(item) },
            { label: '[en]Journal[/en][ja]ジャーナル[/ja]', value: this.renderJournal(item) },
            { label: '[en]Meeting[/en][ja]会議[/ja]', value: this.renderMeeting(item) },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Full Text[/en][ja]本文[/ja]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="article_fulltext_file" rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: 'Rights', value: <ItemTypeField.Rights lang={lang} rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: '[en]Release Date[/en][ja]本公開日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.date} onlyDate={true} /> },
            { label: '[en]Keyword[/en][ja]キーワード[/ja]', value: <ItemTypeField.Repeatable lang={lang} data={item.keyword} /> },
            { label: '[en]Related item[/en][ja]関連アイテム[/ja]', value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
            { label: '[en]Index[/en][ja]インデックス[/ja]', value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: '[en]Item Owner[/en][ja]アイテム所有者[/ja]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[en]Registered Date[/en][ja]登録日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[en]Last Update[/en][ja]最終更新日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[en]Change Log[/en][ja]更新履歴[/ja]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
        ];
        return fields;
    }
}

export default ArticleDetail;