import React from 'react';
import { Helmet } from 'react-helmet';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import { Link } from 'react-router-dom';

interface Props {
    lang: MultiLang;
}

const message = {
    ja: <div>
        <p>サイトアーカイブ化に伴い、本機能の提供を終了しました。</p>
        <p>このサイトのデータについては、<Link to="/modules/xoonips">こちら</Link>から検索可能です。</p>
    </div>,
    en: <div>
        <p>Due to site archiving, the across platform resource search has been discontinued.</p>
        <p>You can search the data only this site <Link to="/modules/xoonips">here</Link>.</p>
    </div>
};

const Xsearch: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Across Platform Resource Search[/en][ja]日本ノードプラットフォーム横断検索[/ja]', lang);
    return <div>
        <Helmet><title>{title + ' - ' + Functions.mlang(Config.SITE_TITLE, lang)}</title></Helmet>
        <h1>{title}</h1>
        <NoticeSiteHasBeenArchived lang={lang} />
        {message[lang]}
    </div>;
}

export default Xsearch;
