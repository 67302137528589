import icon from './icon.png';

const ArticleUtil = {
    type: 'article',
    label: 'Aritcle',
    description: '[en]Article[/en][ja]アーティクル[/ja]',
    icon,
    getDOI: (id:string): string => {
        if (/^aini201[4-8]\..*$/.test(id)) {
            return '10.14931/' + id;
        }
        return '';
    }
};

export default ArticleUtil;