import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import BulletinArchive from './lib/BulletinArchive';
import BulletinContext from './lib/BulletinContext';
import BulletinStories from './lib/BulletinStories';
import BulletinStory from './lib/BulletinStory';
import BulletinUtils from './lib/BulletinUtils';

interface Props {
    lang: MultiLang;
    mydirname: string;
}

interface Props {
    lang: MultiLang;
    mydirname: string;
}

interface State {
    loading: boolean;
    context: BulletinContext | null;
}

class Bulletin extends React.Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            context: null,
        };
        this.isActive = false;
    }

    componentDidMount() {
        this.isActive = true;
        this.updateContext();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { mydirname } = this.props;
        const prevMydirname = prevProps.mydirname;
        if (mydirname !== prevMydirname) {
            this.setState({ loading: true, context: null });
            this.updateContext();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updateContext() {
        const { mydirname } = this.props;
        const context = await BulletinUtils.getContext(mydirname);
        if (this.isActive) {
            this.setState({ loading: false, context });
        }
    }

    private str2int(str: string | null): number | null {
        if (str === null || str.match(/^\d+$/) === null) {
            return null;
        }
        return parseInt(str, 10);
    }

    render() {
        const { lang, mydirname } = this.props;
        const { loading, context } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (context === null) {
            return <PageNotFound lang={lang} />;
        }
        return <div className={`bulletin ${mydirname}`}>
            <Switch>
                <Route exact path={context.getUrl('')} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const url = context.getUrl('index.php' + location.search + location.hash);
                    return <Redirect to={url} />
                }} />
                <Route exact path={context.getUrl('index.php')} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const page = params.get('page') || 'main';
                    switch (page) {
                        case 'main': {
                            const topicId = this.str2int(params.get('storytopic')) || 0;
                            const storyNum = this.str2int(params.get('storynum')) || 5;
                            const start = this.str2int(params.get('start')) || 0;
                            return <BulletinStories lang={lang} context={context} topicId={topicId} storyNum={storyNum} start={start} />
                        }
                        case 'article': {
                            const storyId = this.str2int(params.get('storyid')) || 0;
                            return <BulletinStory lang={lang} context={context} id={storyId} />
                        }
                        case 'archive': {
                            const year = this.str2int(params.get('year')) || 0;
                            const month = this.str2int(params.get('month')) || 0;
                            return <BulletinArchive lang={lang} context={context} year={year} month={month} />
                        }
                    }
                    return <PageNotFound lang={lang} />
                }} />
                <Route render={(props: RouteComponentProps) => {
                    return <PageNotFound lang={lang} />;
                }} />
            </Switch>
        </div>;
    }
}

export default Bulletin;