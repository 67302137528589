import React from 'react';
import { ItemBasicLangs, ItemJournalPublisherLocations } from '../../lib/ItemUtil';
import AdvancedSearchBase, { AdvancedSearchBaseProps } from '../lib/AdvancedSearchBase';
import JournalUtil from './JournalUtil';

class JournalAdvancedSearch extends AdvancedSearchBase {

    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = JournalUtil.type;
        this.title = JournalUtil.label;
        const now = new Date();
        const year = String(now.getFullYear());
        const month = String(now.getMonth() + 1);
        const mday = String(now.getDate());
        this.state.values['doi'] = '';
        this.state.values['title'] = '';
        this.state.values['journal'] = '';
        this.state.values['volume'] = '';
        this.state.values['publication_year'] = '';
        this.state.values['publication_month'] = '';
        this.state.values['publisher'] = '';
        this.state.values['publisher_location'] = '';
        this.state.values['relation'] = '';
        this.state.values['lang'] = '';
        this.state.values['keyword'] = '';
        this.state.values['date_from_year'] = year;
        this.state.values['date_from_month'] = month;
        this.state.values['date_from_mday'] = mday;
        this.state.values['date_to_year'] = year;
        this.state.values['date_to_month'] = month;
        this.state.values['date_to_mday'] = mday;
        this.setIgnoreKey('date_from_year');
        this.setIgnoreKey('date_from_month');
        this.setIgnoreKey('date_from_mday');
        this.setIgnoreKey('date_to_year');
        this.setIgnoreKey('date_to_month');
        this.setIgnoreKey('date_to_mday');
    }

    renderDate() {
        return (
            <>
                <div>
                    {this.renderFieldDate('From', 'date_from_year', 'date_from_month', 'date_from_mday')}

                </div>
                <div>
                    {this.renderFieldDate('To', 'date_to_year', 'date_to_month', 'date_to_mday')}
                </div>
            </>
        );
    }

    getRows() {
        const rows = [
            { label: 'ID', value: this.renderFieldInputText('doi', 50) },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: this.renderFieldInputText('title', 50) },
            { label: '[en]Journal[/en][ja]ジャーナル名[/ja]', value: this.renderFieldInputText('journal', 50) },
            { label: '[en]Volume[/en][ja]巻[/ja]', value: this.renderFieldInputText('volume', 50) },
            { label: '[en]Publication Year[/en][ja]発行年[/ja]', value: this.renderFieldInputText('publication_year', 10) },
            { label: '[en]Publication Month[/en][ja]発行月[/ja]', value: this.renderFieldInputText('publication_month', 10) },
            { label: '[en]Publisher[/en][ja]出版者[/ja]', value: this.renderFieldInputText('publisher', 50) },
            { label: '[en]Location[/en][ja]出版地[/ja]', value: this.renderFieldSelect('publisher_location', ItemJournalPublisherLocations) },
            { label: '[en]Language[/en][ja]言語[/ja]', value: this.renderFieldSelect('lang', ItemBasicLangs) },
            { label: '[en]Publication Date[/en][ja]発行日[/ja]', value: this.renderDate() },
            { label: '[en]Keywords[/en][ja]キーワード[/ja]', value: this.renderFieldInputText('keyword', 50) },
        ];
        return rows;
    }
}

export default JournalAdvancedSearch;