import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import './assets/pico_main.css';
import PicoContext from './lib/PicoContext';
import PicoUtils from './lib/PicoUtils';
import PicoCategory from './PicoCategory';
import PicoContent from './PicoContent';
import PicoMenu from './PicoMenu';

interface Props {
    lang: MultiLang;
    dirname: string;
    virtualpath: string;
    showTitle: boolean;
}

interface State {
    loading: boolean;
    context: PicoContext | null;
}

class Pico extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            context: null,
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, dirname, virtualpath } = this.props;
        if (prevProps.lang !== lang || prevProps.dirname !== dirname || prevProps.virtualpath !== virtualpath) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        const { dirname, showTitle } = this.props;
        const context = PicoUtils.getContext(dirname, showTitle);
        const status = await context.initialize();
        if (this.isActive) {
            this.setState({ loading: false, context: status ? context : null });
        }
    }

    render() {
        const { lang, dirname, virtualpath } = this.props;
        const { loading, context } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (context === null) {
            return <PageNotFound lang={lang} />;
        }
        const module = context.getModule();
        if (module === null) {
            return <PageNotFound lang={lang} />;
        }
        const modulepath = context.getUrl('');
        return <Switch>
            <Route exact path={modulepath} render={(props: RouteComponentProps) => {
                const { location } = props;
                const url = modulepath + '/index.php' + location.search + location.hash;
                return <Redirect to={url} />
            }} />
            <Route render={(props: RouteComponentProps) => {
                const { location } = props;
                const { pathname, search } = location;
                const regexVirutal = new RegExp(`^${virtualpath}`);
                const regexModule = new RegExp(`^${dirname}/?`);
                let link = pathname.replace(regexVirutal, dirname).replace(regexModule, '');
                if (link + '/' === virtualpath) {
                    link = '';
                }
                if (link.match(/.html$/)) {
                    const content = context.getContentByPath(link);
                    if (content !== null) {
                        return <PicoContent lang={lang} context={context} contentId={content.id} />;
                    }
                    return <PageNotFound lang={lang} />;
                }
                const category = context.getCategoryByPath(link);
                if (category !== null) {
                    if (module.show_listasindex !== 0) {
                        return <PicoCategory lang={lang} context={context} catId={category.id} />
                    }
                    const content = context.getFirstContent(category.id);
                    if (content !== null) {
                        return <PicoContent lang={lang} context={context} contentId={content.id} />;
                    }
                    return <PageNotFound lang={lang} />;
                }
                if (link === '' || link === 'index.php') {
                    const params = new URLSearchParams(search);
                    const paramCatId = params.get('cat_id');
                    if (paramCatId !== null && paramCatId.match(/^\d+$/)) {
                        const catId = parseInt(paramCatId, 10);
                        return <PicoCategory lang={lang} context={context} catId={catId} />;
                    }
                    const paramContentId = params.get('content_id');
                    if (paramContentId !== null && paramContentId.match(/^\d+$/)) {
                        const contentId = parseInt(paramContentId, 10);
                        return <PicoContent lang={lang} context={context} contentId={contentId} />;
                    }
                    const paramPage = params.get('page');
                    if ((paramPage !== null && paramPage === 'menu') || module.show_menuinmoduletop !== 0) {
                        return <PicoMenu lang={lang} context={context} />;
                    }
                }
                return <PageNotFound lang={lang} />;
            }} />
        </Switch>;
    }
}

export default Pico;
