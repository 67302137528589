import imageRssBmi from './assets/images/rss-bmi.png';
import imageRssBsiNi from './assets/images/rss-bsi-ni.png';
import imageRssCbsn from './assets/images/rss-cbsn.png';
import imageRssCdtdb from './assets/images/rss-cdtdb.png';
import imageRssCerebellum from './assets/images/rss-cerebellum.png';
import imageRssDynamicbrain from './assets/images/rss-dynamicbrain.png';
import imageRssIncf from './assets/images/rss-incf.png';
import imageRssInvbrain from './assets/images/rss-invbrain.png';
import imageRssMpdb from './assets/images/rss-mpdb.png';
import imageRssNimg from './assets/images/rss-nimg.png';
import imageRssVisiome from './assets/images/rss-visiome.png';
import { RssFeedSite } from './lib/RssFeed';

const RssFeedSites: { [key: string]: RssFeedSite } = {
    'incf': {
        id: 'incf',
        name: 'INCF Neuroinformatics Portal',
        logo: imageRssIncf,
        url: 'https://www.incf.org/',
        rss: 'https://www.incf.org/news/feed'
    },
    'visiome':
    {
        id: 'visiome',
        name: 'Visiome Platform',
        logo: imageRssVisiome,
        url: 'https://visiome.neuroinf.jp/',
        rss: 'https://visiome.neuroinf.jp/rss.xml'
    },
    'bmi':
    {
        id: 'bmi',
        name: 'Brain Machine Interface Platform',
        logo: imageRssBmi,
        url: 'https://bmi.neuroinf.jp/',
        rss: 'https://bmi.neuroinf.jp/researchers/rss.xml'
    },
    'invbrain':
    {
        id: 'invbrain',
        name: 'Invertebrate Brain Platform',
        logo: imageRssInvbrain,
        url: 'https://invbrain.neuroinf.jp/',
        rss: 'https://invbrain.neuroinf.jp/rss.xml'
    },
    'cbsn':
    {
        id: 'cbsn',
        name: 'Comprehensive Brain Science Network Platform',
        logo: imageRssCbsn,
        url: 'https://cbsn.neuroinf.jp/',
        rss: 'https://cbsn.neuroinf.jp/rss.xml'
    },
    'cdtdb':
    {
        id: 'cdtdb',
        name: 'Brain Transcriptome Database',
        logo: imageRssCdtdb,
        url: 'http://www.cdtdb.neuroinf.jp/',
        rss: 'http://www.cdtdb.neuroinf.jp/CDT/cdtdb.xml'
    },
    'cerebellum':
    {
        id: 'cerebellum',
        name: 'Cerebellar Platform',
        logo: imageRssCerebellum,
        url: 'https://cerebellum.neuroinf.jp/',
        rss: 'https://cerebellum.neuroinf.jp/rss.xml'
    },
    'nimg': {
        id: 'nimg',
        name: 'Neuro-Imaging Platform',
        logo: imageRssNimg,
        url: 'https://nimg.neuroinf.jp/',
        rss: 'https://nimg.neuroinf.jp/rss.xml'
    },
    'dynamicbrain':
    {
        id: 'dynamicbrain',
        name: 'Dynamic Brain Platform',
        logo: imageRssDynamicbrain,
        url: 'https://dynamicbrain.neuroinf.jp/',
        rss: 'https://dynamicbrain.neuroinf.jp/rss.xml'
    },
    'mpdb':
    {
        id: 'mpdb',
        name: 'Mouse Phenotype Database',
        logo: imageRssMpdb,
        url: 'http://www.mouse-phenotype.org/',
        rss: 'http://www.mouse-phenotype.org/index.xml'
    },
    'bsi-ni':
    {
        id: 'bsi-ni',
        name: 'BSI-Neuroinfomatics',
        logo: imageRssBsiNi,
        url: 'https://bsi-ni.brain.riken.jp/',
        rss: 'https://bsi-ni.brain.riken.jp/index.xml'
    }
};

export default RssFeedSites;
