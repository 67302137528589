import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../config';
import imageCbsBanner from './assets/images/cbs-banner.png';
import imageIncfBanner from './assets/images/incf-banner.png';
import imageNiuBanner from './assets/images/niu-banner.png';
import imageRikenBanner from './assets/images/riken-banner.png';
import imageXoonisBanner from './assets/images/xoonips-banner.png';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}

const Footer: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <footer id="footer">
            <ul className="banners">
                <li><a href="http://www.riken.jp/" target="_blank" rel="noopener noreferrer"><img src={imageRikenBanner} alt="RIKEN" /></a></li>
                <li><a href="https://cbs.riken.jp/" target="_blank" rel="noopener noreferrer"><img src={imageCbsBanner} alt="RIKEN Center for Brain Science" /></a></li>
                <li><a href="https://www.ni.riken.jp/" target="_blank" rel="noopener noreferrer"><img src={imageNiuBanner} alt="Neuroinformatics Unit, RIKEN Center for Brain Science" /></a></li>
                <li><a href="https://www.incf.org" target="_blank" rel="noopener noreferrer"><img src={imageIncfBanner} alt="International Neuroinformatics Coordinating Facility" /></a></li>
                <li><a href="https://xoonips.osdn.jp/" target="_blank" rel="noopener noreferrer"><img src={imageXoonisBanner} alt="XooNIps" /></a></li>
                <li><Link className="credits" to="/modules/credits/">{Functions.mlang('[en]Site Information[/en][ja]このサイトについて[/ja]', lang)}</Link></li>
            </ul>
            <span className="copyright">Copyright (C) 2018 Neuroinformatics Unit, RIKEN Center for Brain Science</span>
        </footer>
    );
}

export default Footer;
