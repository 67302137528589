import GeneralDataTop from './GeneralDataTop';
import GeneralDataList from './GeneralDataList';
import GeneralDataDetail from './GeneralDataDetail';
import GeneralDataAdvancedSearch from './GeneralDataAdvancedSearch';

const ItemTypeGeneralData = {
    Top: GeneralDataTop,
    List: GeneralDataList,
    Detail: GeneralDataDetail,
    AdvancedSearch: GeneralDataAdvancedSearch,
};

export default ItemTypeGeneralData;