import split from 'split-string';

type AdvancedSearchQueryData = Map<string, URLSearchParams>;

class AdvancedSearchQuery {

    private dataset: AdvancedSearchQueryData = new Map();

    empty() {
        let ret = true;
        this.dataset.forEach((data) => {
            data.forEach((value) => {
                value = value.trim();
                if (value.length !== 0) {
                    ret = false;
                }
            });
        });
        return ret;
    }

    splitString(str: string): string[] {
        return split(str, { separator: ' ', quotes: true })
            .map((value) => {
                return value.replace(/^['"](.*)['"]$/, (whole, m1) => m1).trim();
            })
            .filter((value) => {
                return value.length > 0;
            });
    }

    set(type: string, key: string, value: string) {
        type = type.trim();
        key = key.trim();
        const data: URLSearchParams = this.dataset.has(type) ? this.dataset.get(type) as URLSearchParams : new URLSearchParams();
        data.set(key, value);
        this.dataset.set(type, data);
    }

    delete(type: string, key: string) {
        type = type.trim();
        key = key.trim();
        const data: URLSearchParams = this.dataset.has(type) ? this.dataset.get(type) as URLSearchParams : new URLSearchParams();
        data.delete(key);
        this.dataset.set(type, data);
    }

    deleteType(type: string) {
        type = type.trim();
        this.dataset.delete(type.trim());
    }

    getQueryParams() {
        let ret: URLSearchParams = new URLSearchParams();
        this.dataset.forEach((data, type) => {
            data.forEach((value, key) => {
                value = value.trim();
                value.length > 0 && ret.set(type + '.' + key, value);
            });
        });
        return ret;
    }

    setByQueryString(queryString: string) {
        const query = new URLSearchParams(queryString);
        query.forEach((v, k) => {
            const [type, ...key] = k.split('.');
            if (typeof key === 'undefined') {
                return;
            }
            this.set(type, key.join('.'), v);
        });
    }

    getSearchFilter() {
        const regex = (str: string) => {
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }
        const filter: any = [];
        this.dataset.forEach((data, type) => {
            const filter2: any = [];
            let journal_date_from: number = NaN;
            let journal_date_from_year: number = NaN;
            let journal_date_from_month: number = NaN;
            let journal_date_from_mday: number = NaN;
            let journal_date_to: number = NaN;
            let journal_date_to_year: number = NaN;
            let journal_date_to_month: number = NaN;
            let journal_date_to_mday: number = NaN;
            let article_meeting_date_from_year: number = NaN;
            let article_meeting_date_from_month: number = NaN;
            let article_meeting_date_from_mday: number = NaN;
            let article_meeting_date_to_year: number = NaN;
            let article_meeting_date_to_month: number = NaN;
            let article_meeting_date_to_mday: number = NaN;
            let article_date_from: number = NaN;
            let article_date_from_year: number = NaN;
            let article_date_from_month: number = NaN;
            let article_date_from_mday: number = NaN;
            let article_date_to: number = NaN;
            let article_date_to_year: number = NaN;
            let article_date_to_month: number = NaN;
            let article_date_to_mday: number = NaN;
            filter2.push({
                item_type_name: type,
            });
            data.forEach((value, key) => {
                const keys = key.split('.');
                if (keys.length > 0) {
                    if (keys[0] === 'file' && keys.length === 3) {
                        const fileType = keys[1];
                        const subKey = keys[2];
                        filter2.push({
                            file: {
                                '$elemMatch': {
                                    file_type_name: fileType,
                                    [subKey]: { '$regex': [regex(value), 'i'] }
                                }
                            }
                        });
                    } else if (type === 'journal' && keys[0] === 'date_from_year') {
                        journal_date_from_year = parseInt(value, 10);
                    } else if (type === 'journal' && keys[0] === 'date_from_month') {
                        journal_date_from_month = parseInt(value, 10);
                    } else if (type === 'journal' && keys[0] === 'date_from_mday') {
                        journal_date_from_mday = parseInt(value, 10);
                    } else if (type === 'journal' && keys[0] === 'date_to_year') {
                        journal_date_to_year = parseInt(value, 10);
                    } else if (type === 'journal' && keys[0] === 'date_to_month') {
                        journal_date_to_month = parseInt(value, 10);
                    } else if (type === 'journal' && keys[0] === 'date_to_mday') {
                        journal_date_to_mday = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'meeting_date_from_year') {
                        article_meeting_date_from_year = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'meeting_date_from_month') {
                        article_meeting_date_from_month = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'meeting_date_from_mday') {
                        article_meeting_date_from_mday = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'meeting_date_to_year') {
                        article_meeting_date_to_year = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'meeting_date_to_month') {
                        article_meeting_date_to_month = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'meeting_date_to_mday') {
                        article_meeting_date_to_mday = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'date_from_year') {
                        article_date_from_year = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'date_from_month') {
                        article_date_from_month = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'date_from_mday') {
                        article_date_from_mday = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'date_to_year') {
                        article_date_to_year = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'date_to_month') {
                        article_date_to_month = parseInt(value, 10);
                    } else if (type === 'article' && keys[0] === 'date_to_mday') {
                        article_date_to_mday = parseInt(value, 10);
                    } else {
                        filter2.push({ [key]: { '$regex': [regex(value), 'i'] } });
                    }
                }
            });
            if (!isNaN(journal_date_from_year) && !isNaN(journal_date_from_month) && !isNaN(journal_date_from_mday)) {
                journal_date_from = new Date(journal_date_from_year, journal_date_from_month - 1, journal_date_from_mday).getTime() / 1000;
            }
            if (!isNaN(journal_date_to_year) && !isNaN(journal_date_to_month) && !isNaN(journal_date_to_mday)) {
                journal_date_to = new Date(journal_date_to_year, journal_date_to_month - 1, journal_date_to_mday).getTime() / 1000;
            }
            if (!isNaN(journal_date_from) && !isNaN(journal_date_to)) {
                filter2.push({ date: { '$between': [journal_date_from, journal_date_to] } });
            } else if (!isNaN(journal_date_from)) {
                filter2.push({ date: { '$gte': journal_date_from } });
            } else if (!isNaN(journal_date_to)) {
                filter2.push({ date: { '$lte': journal_date_to } });
            }
            if (!isNaN(article_meeting_date_from_year) && !isNaN(article_meeting_date_from_month) && !isNaN(article_meeting_date_from_mday)) {
                const date = new Date(article_meeting_date_from_year, article_meeting_date_from_month - 1, article_meeting_date_from_mday).getTime() / 1000;
                filter2.push({ meeting_date_from: { '$eq': date } });
            }
            if (!isNaN(article_meeting_date_to_year) && !isNaN(article_meeting_date_to_month) && !isNaN(article_meeting_date_to_mday)) {
                const date = new Date(article_meeting_date_to_year, article_meeting_date_to_month - 1, article_meeting_date_to_mday).getTime() / 1000;
                filter2.push({ meeting_date_to: { '$eq': date } });
            }
            if (!isNaN(article_date_from_year) && !isNaN(article_date_from_month) && !isNaN(article_date_from_mday)) {
                article_date_from = new Date(article_date_from_year, article_date_from_month - 1, article_date_from_mday).getTime() / 1000;
            }
            if (!isNaN(article_date_to_year) && !isNaN(article_date_to_month) && !isNaN(article_date_to_mday)) {
                article_date_to = new Date(article_date_to_year, article_date_to_month - 1, article_date_to_mday).getTime() / 1000;
            }
            if (!isNaN(article_date_from) && !isNaN(article_date_to)) {
                filter2.push({ date: { '$between': [article_date_from, article_date_to] } });
            } else if (!isNaN(article_date_from)) {
                filter2.push({ date: { '$gte': article_date_from } });
            } else if (!isNaN(article_date_to)) {
                filter2.push({ date: { '$lte': article_date_to } });
            }
            filter.push({ '$and': filter2 });
        });
        return { '$or': filter };
    }
}

export default AdvancedSearchQuery;