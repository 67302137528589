import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../common/lib/Loading';
import PageNotFound from '../../common/lib/PageNotFound';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import BulletinContext from '../lib/BulletinContext';
import BulletinStoryItem from '../lib/BulletinStoryItem';
import BulletinUtils from '../lib/BulletinUtils';

interface Props {
    lang: MultiLang;
    mydirname: string;
}

interface State {
    loading: boolean;
    context: BulletinContext | null;
}

class BulletinRecentNews extends React.Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            context: null,
        };
        this.isActive = false;
    }

    componentDidMount() {
        this.isActive = true;
        this.updateContext();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { mydirname } = this.props;
        const prevMydirname = prevProps.mydirname;
        if (mydirname !== prevMydirname) {
            this.setState({ loading: true, context: null });
            this.updateContext();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updateContext() {
        const { mydirname } = this.props;
        const context = await BulletinUtils.getContext(mydirname);
        if (this.isActive) {
            this.setState({ loading: false, context });
        }
    }

    render() {
        const { lang } = this.props;
        const { loading, context } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (context === null) {
            return <PageNotFound lang={lang} />;
        }
        const module = context.getModule();
        if (module === null) {
            return <PageNotFound lang={lang} />;
        }
        const stories = context.getStories(0, 10, 0);
        return <div className={`bulletin ${module.dirname} recentnews-block`}>
            {stories.filter((story, index) => index < 3).map((story, index) => {
                return <BulletinStoryItem key={story.id} lang={lang} context={context} story={story} isList={true} />
            })}
            <ul>
                {stories.filter((story, index) => index >= 3).map((story, index) => {
                    return <li key={story.id}><Link to={context.getStoryUrl(story.id)}>{Functions.mlang(story.title, lang)}</Link> ({story.posttime})</li>
                })}
            </ul>
            <div style={{ textAlign: 'right' }}><Link to={context.getTopicUrl(0, 10, 10)}>[more]</Link></div>
        </div>;
    }
}

export default BulletinRecentNews;
