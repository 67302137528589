import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import ItemType from './item-type';
import ItemUtil, { Item } from './lib/ItemUtil';

interface Props {
    lang: MultiLang;
    id: number | null;
    doi: string | null
}

interface State {
    loading: boolean;
    item: Item | null;
}

class XoonipsDetailItem extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            item: null,
        };
    }

    componentDidMount() {
        this.updateItem();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { id, doi } = this.props;
        if (id !== prevProps.id || doi !== prevProps.doi) {
            this.updateItem();
        }
    }

    updateItem() {
        const { id, doi } = this.props;
        if (doi !== null) {
            ItemUtil.getByDoi(doi, (item) => {
                this.setState({ loading: false, item });
            });
        } else if (id !== null) {
            ItemUtil.get(id, (item) => {
                this.setState({ loading: false, item });
            });
        }
    }

    render() {
        const { lang } = this.props;
        if (this.state.loading) {
            return <Loading />;
        }
        if (this.state.item === null) {
            return <PageNotFound lang={lang} />;
        }
        return (
            <>
                <Helmet>
                    <title>{Functions.mlang(this.state.item.title, lang)} - {Functions.mlang('[en]Database[/en][ja]データベース[/ja]', lang)} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{Functions.mlang('[en]Detail[/en][ja]詳細[/ja]', lang)}</h3>
                <br />
                <ItemType.Detail lang={lang} item={this.state.item} />
            </>
        );
    }
}

export default XoonipsDetailItem;