import React from 'react';
import ItemTypeArticle from './article';
import ItemTypeGeneralData from './generaldata';
import ItemTypeJournal from './journal';
import { AdvancedSearchBaseProps } from './lib/AdvancedSearchBase';
import { DetailBaseProps } from './lib/DetailBase';
import { ListBaseProps } from './lib/ListBase';
import { TopBaseProps } from './lib/TopBase';

interface TopProps extends TopBaseProps {
    type: string;
}
const Top = (props: TopProps) => {
    const { lang, type } = props;
    switch (type) {
        case 'journal':
            return <ItemTypeJournal.Top lang={lang} />;
        case 'article':
            return <ItemTypeArticle.Top lang={lang} />;
        case 'generaldata':
            return <ItemTypeGeneralData.Top lang={lang} />;
        default:
            return null;
    }
}

const List = (props: ListBaseProps) => {
    const { item } = props;
    switch (item.item_type_name) {
        case 'journal':
            return <ItemTypeJournal.List {...props} />;
        case 'article':
            return <ItemTypeArticle.List {...props} />;
        case 'generaldata':
            return <ItemTypeGeneralData.List {...props} />;
        default:
            return null;
    }
}

const Detail = (props: DetailBaseProps) => {
    const { item } = props;
    switch (item.item_type_name) {
        case 'journal':
            return <ItemTypeJournal.Detail {...props} />;
        case 'article':
            return <ItemTypeArticle.Detail {...props} />;
        case 'generaldata':
            return <ItemTypeGeneralData.Detail {...props} />;
        default:
            return null;
    }
}

interface AdvancedSearchProps extends AdvancedSearchBaseProps {
    type: string;
}
const AdvancedSearch = (props: AdvancedSearchProps) => {
    const { lang, type, query } = props;
    switch (type) {
        case 'journal':
            return <ItemTypeJournal.AdvancedSearch lang={lang} query={query} />;
        case 'article':
            return <ItemTypeArticle.AdvancedSearch {...props} />;
        case 'generaldata':
            return <ItemTypeGeneralData.AdvancedSearch lang={lang} query={query} />;
        default:
            return null;
    }
}

const ItemType = {
    Top,
    List,
    Detail,
    AdvancedSearch
}

export default ItemType;