import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import ItemType from './item-type';
import AdvancedSearchQuery from './lib/AdvancedSearchQuery';
import ItemUtil from './lib/ItemUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

class XoonipsAdvancedSearch extends Component<Props> {

    private query: AdvancedSearchQuery = new AdvancedSearchQuery();

    constructor(props: Props) {
        super(props);
        this.handleClickSearchButton = this.handleClickSearchButton.bind(this);
    }

    handleClickSearchButton() {
        if (!this.query.empty()) {
            const url = ItemUtil.getSearchByAdvancedKeywordsUrl(this.query);
            this.props.history.push(url);
        }
    }

    render() {
        const { lang } = this.props;
        return (
            <div className="advancedSearch">
                <h3>{Functions.mlang('[en]Search Items[/en][ja]アイテム検索[/ja]', lang)}</h3>
                <div className="search">
                    <button className="formButton" onClick={this.handleClickSearchButton}>Search</button>
                </div>
                {Config.XOONIPS_ITEMTYPES.map((type) => {
                    return <ItemType.AdvancedSearch key={type} type={type} lang={lang} query={this.query} />;
                })}
                <div className="search">
                    <button className="formButton" onClick={this.handleClickSearchButton}>Search</button>
                </div>
            </div>
        );
    }
}

export default XoonipsAdvancedSearch;