import React, { ReactNode } from 'react';
import { MultiLang } from '../../../../config';

interface Props {
    lang: MultiLang;
    data: ReactNode[];
}

const Repeatable = (props: Props) => {
    const { data } = props;
    if (data.length === 0) {
        return null;
    }
    const elements = data.map((value, idx) => {
        const evenodd = idx % 2 === 0 ? 'even' : 'odd';
        return <tr key={idx}><td className={evenodd}>{value}</td></tr>;
    });
    return <table><tbody>{elements}</tbody></table>;
}

export default Repeatable;