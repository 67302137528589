import AsyncLock from 'async-lock';
import axios from 'axios';
import loki from 'lokijs';
import funcs from '../../functions';
import simpfLinksJson from '../assets/simpf-links.json';
import AdvancedSearchQuery from './AdvancedSearchQuery';

interface SimPFLink {
    id: number;
    url: string;
}

interface ItemSelectType<T> {
    type: T;
    label: string;
}

export type ItemSelectTypes<T> = readonly ItemSelectType<T>[];

export type ItemBasicLang = 'eng' | 'jpn' | 'fra' | 'deu' | 'esl' | 'ita' | 'dut' | 'sve' | 'nor' | 'dan' | 'fin' | 'por' | 'chi' | 'kor';
export const ItemBasicLangs: ItemSelectTypes<ItemBasicLang> = [
    { type: 'eng', label: '[en]English[/en][ja]英語[/ja]' },
    { type: 'jpn', label: '[en]Japanese[/en][ja]日本語[/ja]' },
    { type: 'fra', label: '[en]French[/en][ja]フランス語[/ja]' },
    { type: 'deu', label: '[en]German[/en][ja]ドイツ語[/ja]' },
    { type: 'esl', label: '[en]Spanish[/en][ja]スペイン語[/ja]' },
    { type: 'ita', label: '[en]Italian[/en][ja]イタリア語[/ja]' },
    { type: 'dut', label: '[en]Dutch[/en][ja]オランダ語[/ja]' },
    { type: 'sve', label: '[en]Swedish[/en][ja]スウェーデン語[/ja]' },
    { type: 'nor', label: '[en]Norwegian[/en][ja]ノルウェー語[/ja]' },
    { type: 'dan', label: '[en]Danish[/en][ja]デンマーク語[/ja]' },
    { type: 'fin', label: '[en]Finnish[/en][ja]フィンランド語[/ja]' },
    { type: 'por', label: '[en]Portuguese[/en][ja]ポルトガル語[/ja]' },
    { type: 'chi', label: '[en]Chinese[/en][ja]中国語[/ja]' },
    { type: 'kor', label: '[en]Korean[/en][ja]韓国語[/ja]' }
];

export interface ItemBasicIndex {
    index_id: number;
    title: string;
}
export interface ItemBasicChangeLog {
    log_date: number;
    log: string;
}
export interface ItemBasicFile {
    file_id: number;
    original_file_name: string;
    mime_type: string;
    file_size: number;
    caption: string;
    timestamp: number;
    file_type_name: string;
    file_type_display_name: string;
}
export interface ItemCore {
    item_id: number;
    doi: string;
}
export interface ItemBasic extends ItemCore {
    uid: number;
    description: string;
    last_update_date: number;
    creation_date: number;
    publication_year: number;
    publication_month: number;
    publication_mday: number;
    lang: ItemBasicLang;
    title: string;
    item_type_display_name: string;
    item_type_name: string;
    uname: string;
    name: string;
    item_url: string;
    index: ItemBasicIndex[];
    changelog: ItemBasicChangeLog[];
    related_to: number[];
    keyword: string[];
    file: ItemBasicFile[];
}


export type ItemJournalPublisherLocation = 'USA' | 'JPN';
export const ItemJournalPublisherLocations: ItemSelectTypes<ItemJournalPublisherLocation> = [
    { type: 'USA', label: '[en]United States[/en][ja]アメリカ[/ja]' },
    { type: 'JPN', label: '[en]Japan[/en][ja]日本[/ja]' }
];
export interface ItemJournal extends ItemBasic {
    journal: string;
    volume: string;
    publisher: string;
    publisher_location: ItemJournalPublisherLocation;
    rights: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    date: number;
}

export interface ItemArticle extends ItemBasic {
    author: string[];
    affiliation: string[];
    journal_name: string;
    journal_volume: string;
    journal_page: string;
    journal_publisher: string;
    meeting_name: string;
    meeting_place: string;
    meeting_date_from: number;
    meeting_date_to: number;
    meeting_program_no: string;
    rights: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    date: number;
}

export interface ItemGeneralData extends ItemBasic {
    rights: string;
    use_cc: number;
    cc_commercial_use: number;
    cc_modification: number;
    creator: string[];
    affiliation: string[];
    date: number;
    publisher: string;
    version: string;
    format: string;
    variation: string;
    relation: string[];
}

export type Item = ItemJournal | ItemArticle | ItemGeneralData;

export interface KeywordSearchQuery {
    type: string;
    keyword: string;
}

export type SortConditionLimit = 20 | 50 | 100;
export type SortConditionOrderBy = 'title' | 'doi' | 'last_update_date' | 'creation_date' | 'publication_date';
export enum SortConditionOrderDir { ASC, DESC }

export interface SortCondition {
    limit: SortConditionLimit;
    orderBy: SortConditionOrderBy;
    orderDir: SortConditionOrderDir;
    page: number;
}

class ItemSorter {

    public orderBy: SortConditionOrderBy;
    public orderDir: SortConditionOrderDir;

    constructor(condition: SortCondition) {
        this.orderBy = condition.orderBy;
        this.orderDir = condition.orderDir;
        this.sort = this.sort.bind(this);
    }

    sort(a: Item, b: Item) {
        let av: string = '';
        let bv: string = '';
        switch (this.orderBy) {
            case 'title':
                av = a.title.toLocaleUpperCase();
                bv = b.title.toLocaleUpperCase();
                break;
            case 'doi':
                av = a.doi.toLocaleUpperCase();
                bv = b.doi.toLocaleUpperCase();
                break;
            case 'last_update_date':
                av = String(a.last_update_date);
                bv = String(b.last_update_date);
                break;
            case 'creation_date':
                av = String(a.creation_date);
                bv = String(b.creation_date);
                break;
            case 'publication_date':
                av = String(a.publication_year * 10000 + a.publication_month * 100 + a.publication_mday);
                bv = String(b.publication_year * 10000 + b.publication_month * 100 + b.publication_mday);
                break;
            default:
                break;
        }
        if (this.orderDir === SortConditionOrderDir.ASC) {
            if (av > bv) return -1;
            else if (av < bv) return 1;
        } else {
            if (av > bv) return 1;
            else if (av < bv) return -1;
        }
        return 0;
    }
}

type GetResult = Item | null;
export interface GetCallbackFunc { (item: GetResult): void }
export interface SearchResult {
    total: number;
    data: Item[];
}
export interface SearchCallbackFunc { (results: SearchResult): void }
export interface SearchFunc { (condition: SortCondition, func: SearchCallbackFunc): void }

interface ItemLoadCallbackFunc { (items: Collection<Item>): void }

class ItemUtil {

    private database: loki;
    private items: Collection<Item>;
    private simpfLinks: Collection<SimPFLink>;
    private loading: boolean;
    private callbacks: ItemLoadCallbackFunc[];

    constructor() {
        this.database = new loki('database');
        this.items = this.database.addCollection('items');
        this.simpfLinks = this.database.addCollection('simpf-links');
        this.loading = true;
        this.callbacks = [];
        this.load();
    }

    load(): void {
        axios.get('/modules/xoonips/items.json', { responseType: 'json' }).then((response) => {
            const itemsJson = response.data as Item[];
            itemsJson.forEach((json: Item) => {
                this.items.insert(json);
            });
            const lock = new AsyncLock();
            lock.acquire('items', () => {
                this.loading = false;
                this.callbacks.forEach((callback) => {
                    callback(this.items);
                });
                this.callbacks = [];
            });
        });
        const simpfLinks = simpfLinksJson as SimPFLink[];
        simpfLinks.forEach((simpfLink: SimPFLink) => {
            this.simpfLinks.insert(simpfLink);
        });
    }

    registerItemLoadCallback(func: ItemLoadCallbackFunc): void {
        const lock = new AsyncLock();
        lock.acquire('items', () => {
            if (this.loading) {
                this.callbacks.push(func);
            } else {
                func(this.items);
            }
        });
    }

    getUrl(item: ItemCore): string {
        const query = new URLSearchParams();
        if (item.doi !== '') {
            query.set('id', item.doi);
        } else {
            query.set('item_id', item.item_id.toString());
        }
        return `/modules/xoonips/detail.php?${query.toString()}`;
    }

    getFileUrl(file: ItemBasicFile): string {
        return '/modules/xoonips/file/' + String(file.file_id) + '/' + funcs.escape(file.original_file_name);
    }

    getPreviewFileUrl(file: ItemBasicFile): string {
        return '/modules/xoonips/file/' + String(file.file_id) + '.png';
    }

    getSearchByKeywordUrl(type: string, keyword: string): string {
        const params = new URLSearchParams({
            op: 'quick',
            search_condition: type,
            keyword
        });
        return '/modules/xoonips/search.php?' + params.toString();
    }

    getItemTypeSearchUrl(type: string | null, subType: string | null): string {
        if (type === null || subType === null) {
            return '/';
        }
        const query = new URLSearchParams();
        if (subType === '') {
            query.set('op', 'advanced');
            query.set('search_itemtype', `${type}`);
        } else {
            query.set('op', 'itemsubtypesearch');
            query.set(`${type}`, 'on');
            query.set(`${type}_subtype`, subType);
        }
        return `/modules/xoonips/search.php?${query.toString()}`;
    }

    getSearchByAdvancedKeywordsUrl(query: AdvancedSearchQuery): string {
        return '/modules/xoonips/search.php?op=advanced&' + query.getQueryParams().toString();
    }

    getSearchKeywordByQuery(queryString: string): KeywordSearchQuery {
        const query = new URLSearchParams(queryString);
        const type = query.get('search_condition') || 'all';
        const keyword = query.get('keyword') || '';
        return ({ type, keyword });
    }

    getAdvancedSearchQueryByQuery(queryString: string): AdvancedSearchQuery {
        const query: AdvancedSearchQuery = new AdvancedSearchQuery();
        query.setByQueryString(queryString);
        return query;
    }

    get(itemId: number, func: GetCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter = {
                item_id: itemId
            }
            const item = items.findOne(filter);
            func(item);
        });
    }

    getByDoi(doi: string, func: GetCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter = {
                doi: doi
            }
            const item = items.findOne(filter);
            func(item);
        });
    }

    getList(itemIds: number[], func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter = {
                item_id: {
                    '$in': itemIds,
                }
            }
            const sort = (a: Item, b: Item) => {
                const aIdx = itemIds.findIndex((itemId) => { return a.item_id === itemId; });
                const bIdx = itemIds.findIndex((itemId) => { return b.item_id === itemId; });
                if (aIdx > bIdx) {
                    return 1;
                } else if (aIdx < bIdx) {
                    return -1;
                }
                return 0;
            }
            const data = items.chain().find(filter).sort(sort).data();
            const res = {
                total: data.length,
                data: data,
            }
            func(res);
        });
    }

    getListByIndexId(indexId: number, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter: any = {
                'index.index_id': indexId
            };
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getListByItemType(itemType: string, subItemType: string, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            let filter: any = {
                item_type_name: itemType,
            }
            if (subItemType !== '') {
                switch (itemType) {
                    default:
                        break;
                }
            }
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getListByKeyword(type: string, keyword: string, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const regex = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const num = keyword.match(/^[0-9]+$/) ? parseInt(keyword, 10) : null;
            let filter: any = { '$or': [] };
            const appendToFilter = (type: string, strKeys: string[], numKeys: string[]) => {
                const basicStrKeys: string[] = ['title', 'keyword', 'doi', 'description', 'uname', 'name', 'index.title'];
                const basicNumKeys: string[] = [];
                let filterItemType: any = {
                    item_type_name: type,
                    '$or': []
                };
                let sKeys: string[] = basicStrKeys.concat(strKeys);
                let nKeys: string[] = basicNumKeys.concat(numKeys);
                sKeys.forEach((key) => {
                    let criteria: any = {};
                    criteria[key] = { '$regex': [regex, 'i'] };
                    filterItemType['$or'].push(criteria);
                });
                if (num !== null) {
                    nKeys.forEach((key) => {
                        let criteria: any = {};
                        criteria[key] = { '$eq': num };
                        filterItemType['$or'].push(criteria);
                    });
                }
                filter['$or'].push(filterItemType);
            }
            if (type === 'basic') {
                filter['$or'].push({ 'title': { '$regex': [regex, 'i'] } });
                filter['$or'].push({ 'keyword': { '$regex': [regex, 'i'] } });
            } else {
                if (type === 'all' || type === 'journal') {
                    const strKeys: string[] = ['doi', 'title', 'journal', 'volume', 'publication_year', 'publisher', 'file.original_file_name', 'keyword', 'description'];
                    const numKeys: string[] = [];
                    appendToFilter('journal', strKeys, numKeys);
                }
                if (type === 'all' || type === 'article') {
                    const strKeys: string[] = ['doi', 'title', 'author', 'affiliation', 'journal_name', 'journal_volume', 'publication_year', 'journal_page', 'journal_publisher', 'meeting_name', 'meeting_place', 'meeting_program_no', 'file.original_file_name', 'keyword'];
                    const numKeys: string[] = [];
                    appendToFilter('article', strKeys, numKeys);
                }
                if (type === 'all' || type === 'generaldata') {
                    const strKeys: string[] = ['doi', 'title', 'description', 'file.caption', 'file.original_file_name', 'creator', 'affiliation', 'version', 'format', 'variation', 'relation', 'keyword'];
                    const numKeys: string[] = [];
                    appendToFilter('generaldata', strKeys, numKeys);
                }
                if (type !== 'all') {
                    filter['item_type_name'] = type;
                }
            }
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getListByAdvancedSearchQuery(query: AdvancedSearchQuery, condition: SortCondition, func: SearchCallbackFunc): void {
        this.registerItemLoadCallback((items) => {
            const filter: any = query.getSearchFilter();
            const offset = condition.limit * (condition.page - 1);
            const result = items.chain().find(filter);
            const itemSorter = new ItemSorter(condition);
            const ret = {
                total: result.count(),
                data: result.sort(itemSorter.sort).offset(offset).limit(condition.limit).data()
            };
            func(ret);
        });
    }

    getSimPFLinkUrl(itemId: number) {
        const simpfLink = this.simpfLinks.findOne({ id: itemId });
        if (simpfLink === null) {
            return '';
        }
        return simpfLink.url;
    }
}

export default new ItemUtil();