import React from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import { ItemJournal } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';
import JournalUtil from './JournalUtil';

class JournalList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = JournalUtil.label;
        this.icon = JournalUtil.icon;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemJournal;
        const doi = JournalUtil.getDOI(item.doi);
        return (
            <div className="item">
                <div className="title"><Link to={this.url}>{Functions.mlang(item.title, lang)}</Link> ({item.publication_year})</div>
                <div className="publisher">{Functions.mlang(item.publisher, lang)}</div>
                {doi !== '' && <div className="doi"><a href={`https://doi.org/${doi}`}>doi:{doi}</a></div>}
            </div>
        );
    }
}

export default JournalList;