import React from 'react';
import Functions from '../../../functions';
import { ItemGeneralData } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';

class GeneralDataDetail extends DetailBase {

    renderEdition(item: ItemGeneralData) {
        const { lang } = this.props;
        return <table>
            <tbody>
                <tr>
                    <td style={{width: '130px'}}>{Functions.mlang('[en]Version[/en][ja]バージョン[/ja]', lang)}</td>
                    <td>{Functions.mlang(item.version, lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Format[/en][ja]フォーマット[/ja]', lang)}</td>
                    <td>{Functions.mlang(item.format, lang)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Variation[/en][ja]バリエーション[/ja]', lang)}</td>
                    <td>{Functions.mlang(item.variation, lang)}</td>
                </tr>
            </tbody>
        </table>
    }

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemGeneralData;
        const fields = [
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: 'ID', value: item.doi },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[en]Description[/en][ja]概要[/ja]', value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: '[en]Preview[/en][ja]プレビュー[/ja]', value: <ItemTypeField.Preview lang={lang} file={item.file} /> },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Files[/en][ja]ファイル[/ja]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="generaldata_file" rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: 'Rights', value: <ItemTypeField.Rights lang={lang} rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: '[en]Creator[/en][ja]作成者[/ja]', value: <ItemTypeField.Author lang={lang} author={item.creator} /> },
            { label: '[en]Affiliation[/en][ja]所属[/ja]', value: <ItemTypeField.Repeatable lang={lang} data={item.affiliation} /> },
            { label: '[en]Publication Date[/en][ja]発行日[/ja]', value: <ItemTypeField.PublicationDate lang={lang} year={item.publication_year} month={item.publication_month} mday={item.publication_mday} /> },
            { label: '[en]Publisher[/en][ja]発行者[/ja]', value: Functions.mlang(item.publisher, lang) },
            { label: '[en]Edition[/en][ja]版[/ja]', value: this.renderEdition(item) },
            { label: '[en]Related Links[/en][ja]関連リンク[/ja]', value: <ItemTypeField.Repeatable lang={lang} data={item.relation.map((relation) => <a href={relation} target="_blank" rel="noopener noreferrer">{relation}</a>)} /> },
            { label: '[en]Keyword[/en][ja]キーワード[/ja]', value: <ItemTypeField.Repeatable lang={lang} data={item.keyword} /> },
            { label: '[en]Related item[/en][ja]関連アイテム[/ja]', value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
            { label: '[en]Index[/en][ja]インデックス[/ja]', value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: '[en]Item Owner[/en][ja]アイテム所有者[/ja]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[en]Registered Date[/en][ja]登録日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[en]Last Update[/en][ja]最終更新日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[en]Change Log[/en][ja]更新履歴[/ja]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
        ];
        return fields;
    }
}

export default GeneralDataDetail;