import axios from 'axios';
import loki from 'lokijs';

export interface BulletinModuleData {
    name: string;
    dirname: string;
    storyhome: number;
    displaynav: number;
    disp_list_of_cat: number;
    stories_of_cat: number;
    use_pankuzu: number;
}

export interface BulletinTopicData {
    id: number;
    pid: number;
    title: string;
}

export interface BulletinStoryData {
    id: number;
    posttime: string;
    topic_id: number;
    title: string;
    text: {
        en: string;
        ja: string;
    }
}

interface BulletinIndexData {
    module: BulletinModuleData;
    topics: BulletinTopicData[];
    stories: BulletinStoryData[];
}

interface BulletinCounterData {
    count: {
        [date: string]: number;
    }
    year: {
        min: number;
        max: number;
    }
}

export const BULLETIN_TOPIC_ID_ROOT = 0;

class BulletinContext {

    private isInitialized: boolean;
    private dirname: string;
    private database: loki;
    private module: BulletinModuleData | null;
    private topics: Collection<BulletinTopicData>;
    private stories: Collection<BulletinStoryData>;
    private counter: BulletinCounterData;

    constructor(dirname: string) {
        this.isInitialized = false;
        this.dirname = dirname;
        this.database = new loki(`bulletin_${dirname}`);
        this.module = null;
        this.topics = this.database.addCollection('topics');
        this.stories = this.database.addCollection('stories');
        this.counter = { count: {}, year: { min: 0, max: 0 } };
    }

    getUrl(path: string): string {
        return `/${this.dirname}/${path}`;
    }

    getStoryUrl(id: number, page: number = 0): string {
        const params = new URLSearchParams([
            ['page', 'article'],
            ['storyid', id.toString()]
        ]);
        if (page !== 0) {
            params.set('storypage', page.toString());
        }
        return this.getUrl('index.php?' + params.toString());
    }

    getTopicUrl(id: number, limit: number, start: number): string {
        const params = new URLSearchParams();
        if (id !== 0) {
            params.set('storytopic', id.toString());
        }
        if (limit !== 5) {
            params.set('storynum', limit.toString());
        }
        if (start !== 0) {
            params.set('start', start.toString());
        }
        return this.getUrl('index.php?' + params.toString());
    }

    getArchiveUrl(year: number = 0, month: number = 0): string {
        const params = new URLSearchParams([
            ['page', 'archive']
        ]);
        if (year !== 0) {
            params.set('year', year.toString());
        }
        if (month !== 0) {
            params.set('month', month.toString());
        }
        return this.getUrl('index.php?' + params.toString());
    }

    getModule(): BulletinModuleData | null {
        return this.module;
    }

    getTopic(topicId: number): BulletinTopicData | null {
        return this.topics.findOne({ id: topicId });
    }

    getStory(storyId: number): BulletinStoryData | null {
        return this.stories.findOne({ id: storyId });
    }

    getTopics(): BulletinTopicData[] {
        return this.topics.chain().find().simplesort('title').data();
    }

    getStories(topicId: number, limit: number, start: number): BulletinStoryData[] {
        const filter = topicId === 0 ? undefined : { topic_id: topicId };
        return this.stories.chain().find(filter).simplesort('posttime', true).offset(start).limit(limit).data();
    }

    getStoriesByMonth(year: number, month: number) {
        const key = ('0000' + year).substr(-4) + '-' + ('00' + month).substr(-2);
        const filter = { posttime: { '$regex': ['^' + key] } };
        return this.stories.chain().find(filter).simplesort('posttime', true).data();
    }

    countStories(topicId: number): number {
        const filter = topicId === 0 ? undefined : { topic_id: topicId };
        return this.stories.count(filter);
    }

    getArchiveYearRange(): { min: number, max: number } {
        return this.counter.year;
    }

    getArchiveCounter(year: number, month: number) {
        const key = ('0000' + year).substr(-4) + '-' + ('00' + month).substr(-2);
        return key in this.counter.count ? this.counter.count[key] : 0;
    }

    async initialize(): Promise<boolean> {
        if (!this.isInitialized) {
            try {
                const response = await axios.get('/modules' + this.getUrl('index.json'));
                const index = response.data as BulletinIndexData;
                this.module = index.module;
                index.topics.forEach((value) => {
                    this.topics.insert(value);
                });
                index.stories.forEach((value, index) => {
                    const key = value.posttime.replace(/^(\d{4}-\d{2}).*$/, "$1");
                    const year = parseInt(key.replace(/^(\d{4}).*/, "$1"), 10);
                    if (index === 0) {
                        this.counter.year.min = year;
                        this.counter.year.max = year;
                    } else {
                        if (this.counter.year.min > year) {
                            this.counter.year.min = year;
                        }
                        if (this.counter.year.max < year) {
                            this.counter.year.max = year;
                        }
                    }
                    if (key in this.counter.count) {
                        this.counter.count[key]++;
                    } else {
                        this.counter.count[key] = 1;
                    }
                    this.stories.insert(value);
                });
            } catch (err) {
                // ignore
            }
            this.isInitialized = true;
        }
        return this.isInitialized;
    }
}

export default BulletinContext;