import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import IndexUtil, { Index, INDEX_ID_PUBLIC } from './lib/IndexUtil';
import ItemUtil, { SearchCallbackFunc, SortCondition } from './lib/ItemUtil';
import XoonipsListIndex from './lib/XoonipsListIndex';
import XoonipsListItem from './lib/XoonipsListItem';

interface Props {
    lang: MultiLang;
    id: number | null;
}

class XoonipsSearchByIndexId extends Component<Props> {

    constructor(props: Props) {
        super(props);
        this.searchFunc = this.searchFunc.bind(this);
    }

    getUrl() {
        const { id } = this.props;
        const indexId = id === null ? INDEX_ID_PUBLIC : id;
        if (indexId === 0) {
            return '/';
        }
        return IndexUtil.getUrl(indexId);
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        const { id } = this.props;
        const indexId = id === null ? INDEX_ID_PUBLIC : id;
        if (id === 0) {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByIndexId(indexId, condition, func);
        }
    }

    render() {
        const { lang, id } = this.props;
        const indexId = id === null ? INDEX_ID_PUBLIC : id;
        const index = IndexUtil.get(indexId);
        if (index === null) {
            return <PageNotFound lang={lang} />;
        }
        const baseUrl = this.getUrl();
        const pIndexes = IndexUtil.getParents(indexId);
        const parents = pIndexes.map((value: Index) => {
            const url: string = IndexUtil.getUrl(value.id);
            return <Fragment key={value.id}>/ <Link to={url}>{value.title}</Link> </Fragment>;
        });
        const title = pIndexes.map((value) => { return '/' + value.title; }).join('');
        return (
            <div className="list">
                <Helmet>
                    <title>{Functions.mlang(title, lang)} - {Functions.mlang('[en]Database[/en][ja]データベース[/ja]', lang)} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{Functions.mlang('[en]Listing item[/en][ja]アイテム一覧[/ja]', lang)}</h3>
                <div>{parents}</div>
                <XoonipsListIndex lang={lang} index={index} />
                <XoonipsListItem lang={lang} url={baseUrl} search={this.searchFunc} />
            </div>
        );
    }
}

export default XoonipsSearchByIndexId;
