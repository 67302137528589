import React from 'react';
import Loading from '../../common/lib/Loading';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import RssFeed, { RssFeedItem, RssFeedSite } from '../lib/RssFeed';

interface Props {
    lang: MultiLang;
    sites: RssFeedSite[];
    num: number;
}

interface State {
    loading: boolean;
    feeds: RssFeedItem[] | null;
}

const rssMerge = (a: RssFeedItem[], b: RssFeedItem[], maxlength: number) => {
    return a.concat(b).sort((i, j) => { return i.pubDate === j.pubDate ? 0 : (i.pubDate > j.pubDate ? -1 : 1) }).slice(0, maxlength);
};
const arrayDiff = (a: string[], b: string[]): string[] => { return a.filter((i: string) => { return b.indexOf(i) < 0 }) };

class RssBlock extends React.Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            feeds: null
        };
        this.isActive = false;
    }

    componentDidMount() {
        this.isActive = true;
        this.updateFeed();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { sites, num } = this.props;
        const prevSites = prevProps.sites;
        const prevNum = prevProps.num;
        const siteIds = sites.map((site) => site.id);
        const prevSiteIds = prevSites.map((site) => site.id);
        if (num !== prevNum || arrayDiff(siteIds, prevSiteIds).length !== 0 || arrayDiff(prevSiteIds, siteIds).length !== 0) {
            this.setState({ loading: true, feeds: null });
            this.updateFeed();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updateFeed() {
        const { sites, num } = this.props;
        let feeds: RssFeedItem[] = [];
        const infos = await Promise.all(sites.map(async (site) => RssFeed.getInfo(site)));
        infos.forEach((info) => {
            feeds = rssMerge(feeds, info.items, num);
        })
        if (this.isActive) {
            this.setState({ loading: false, feeds });
        }
    }

    render() {
        const { loading, feeds } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (feeds === null) {
            return <div>Faild to get RSS feeds.</div>;
        }
        const newRange = new Date().getTime() / 1000 - 604800;
        return <div className="feeds">
            {feeds.map((feed, index) => {
                const site = RssFeed.getSite(feed.id);
                const isNew = feed.pubDate > newRange;
                return <div className="feed" key={feed.id + index}>
                    <div className="pubDate">{Functions.formatDate(feed.pubDate, 'YYYY-M-D')}{isNew && <span className="new">New</span>}</div>
                    <div className="logo"><a href={site.url} target="_blank" rel="noopener noreferrer"><img src={site.logo} alt={site.name} /></a></div>
                    <div className="item">
                        <div className="title"><a href={feed.link} target="_blank" rel="noopener noreferrer">{feed.title}</a></div>
                        <div className="site">({site.name})</div>
                    </div>
                </div>
            })}
        </div>;
    }
}

export default RssBlock;