import moment from 'moment';
import RSSParser from 'rss-parser';

export interface RssFeedSite {
    id: string;
    name: string;
    logo: string;
    url: string;
    rss: string;
}

export interface RssFeedItem {
    id: string;
    title: string;
    link: string;
    pubDate: number;
}

export interface RssFeedInfo {
    site: RssFeedSite;
    items: RssFeedItem[];
    loaded: boolean;
}

const CORS_PROXY = "https://archive.ni.brain.riken.jp/cors/";
const feedInfoCache = new Map<string, RssFeedInfo>();

const getInfo = async (site: RssFeedSite): Promise<RssFeedInfo> => {
    const info = feedInfoCache.get(site.id) || { site, items: [], loaded: false };
    if (!info.loaded) {
        info.loaded = true;
        const parser = new RSSParser();
        try {
            const output = await parser.parseURL(CORS_PROXY + site.rss);
            if (typeof output.items !== 'undefined') {
                output.items.forEach((item) => {
                    if (typeof item.title !== 'undefined' && typeof item.link !== 'undefined') {
                        const dateString = item.pubDate || item.isoDate;
                        const pubDate = moment(dateString).unix();
                        if (!isNaN(pubDate)) {
                            info.items.push({
                                id: site.id,
                                title: item.title,
                                link: item.link,
                                pubDate: pubDate,
                            });
                        }
                    }
                });
            }
        } catch (err) {
            // ignore
        }
        feedInfoCache.set(site.id, info);
    }
    return info;
}

const getSite = (id: string) => {
    const info = feedInfoCache.get(id);
    if (typeof info === 'undefined') {
        return { id: id, name: '', logo: '', url: '' };
    }
    return info.site;
}

const RssFeed = {
    getInfo,
    getSite
}

export default RssFeed;