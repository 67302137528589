import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Bulletin from '../bulletin/Bulletin';
import XoopsPathRedirect from '../common/XoopsPathRedirect';
import Config, { MultiLang } from '../config';
import Credits from '../credits/Credits';
import Functions from '../functions';
import Pico from '../pico/Pico';
import XooNIpsIndexTreeBlock from '../xoonips/blocks/IndexTree';
import XooNIpsSearchBlock from '../xoonips/blocks/Search';
import Xoonips from '../xoonips/Xoonips';
import RssBlock from './blocks/RssBlock';
import ContactUs from './ContactUs';
import Footer from './Footer';
import Header from './Header';
import RssFeedSites from './RssFeedSites';
import Sitemap from './Sitemap';
import TopPage from './TopPage';
import Xsearch from './Xsearch';

interface Props {
    lang: MultiLang;
}

const hackathonPathes = ['hackathon2018', 'brainhack-global-2017', 'hackathon2016', 'bah2015'];
const ainiPathes = ['2014', '2015', '2016', '2017', '2018'];
const nbniPathes = ['2014', '2017'];

const Page: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const goPageTop = Functions.mlang('[en]Go Page Top[/en][ja]ページの先頭へ[/ja]', lang);
    return <div id="page">
        <Header lang={lang} />
        <div id="main">
            <Switch>
                <Route exact path="/" render={() => <TopPage lang={lang} />} />
                <Route exact path="/contactus" render={() => <ContactUs lang={lang} />} />
                <Route exact path="/sitemap" render={() => <Sitemap lang={lang} />} />
                <Route exact path="/modules/xsearch" render={() => <Xsearch lang={lang} />} />
                <Route path="/modules/credits" render={() => <Credits lang={lang} />} />
                {ainiPathes.map((name) =>
                    <Route key={name} path={`/aini${name}`} render={() => <Pico lang={lang} dirname="aini" virtualpath={`/aini`} showTitle={false} />} />
                )}
                {nbniPathes.map((name) =>
                    <Route key={name} path={`/nbni${name}`} render={() => <Pico lang={lang} dirname="nbni" virtualpath={`/nbni`} showTitle={false} />} />
                )}
                {hackathonPathes.map((name) =>
                    <Route key={name} path={`/${name}`} render={() => <Pico lang={lang} dirname="hackathon" virtualpath="" showTitle={false} />} />
                )}
                {Config.PICO_MODULES.map((name) =>
                    <Route key={name} path={`/${name}`} render={() => <Pico lang={lang} dirname={name} virtualpath={`/${name}/`} showTitle={false} />} />
                )}
                {Config.PICO_MODULES.map((name) =>
                    <Route key={name} path={'/modules/' + name} render={(props: RouteComponentProps) => {
                        const { pathname, search, hash } = props.history.location;
                        const url = pathname.replace(/^\/modules/, '') + search + hash;
                        return <Redirect to={url} />;
                    }} />
                )}
                {Config.BULLETIN_MODULES.map((name) =>
                    <Route key={name} path={`/${name}`} render={() => <Bulletin lang={lang} mydirname={name} />} />
                )}
                {Config.BULLETIN_MODULES.map((name) =>
                    <Route key={name} path={'/modules/' + name} render={(props: RouteComponentProps) => {
                        const { pathname, search, hash } = props.history.location;
                        const url = pathname.replace(/^\/modules/, '') + search + hash;
                        return <Redirect to={url} />;
                    }} />
                )}
                <Route path="/modules/xoonips" render={() => {
                    return <div className="col2">
                        <div className="leftcolumn">
                            <div className="block">
                                <div className="blockTitle">
                                    Item Search
                            </div>
                                <div className="blockContent">
                                    <XooNIpsSearchBlock lang={lang} />
                                </div>
                            </div>
                            <div className="block">
                                <div className="blockTitle">
                                    Index Tree
                            </div>
                                <div className="blockContent">
                                    <XooNIpsIndexTreeBlock lang={lang} />
                                </div>
                            </div>
                        </div>
                        <div className="centercolumn"><Xoonips lang={lang} /> </div>
                    </div>;
                }} />
                <Route component={XoopsPathRedirect} />
            </Switch>
            <Switch>
                <Route exact path="/resource/platform.html" render={() =>
                    <>
                        <h2 id="link-pf-update">{Functions.mlang('[en]Platform Updates[/en][ja]プラットフォーム更新情報[/ja]', lang)}</h2>
                        <div className="centercolumn">
                            <div className="centerLcolumn">
                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Neuro-Imaging Platform', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.nimg]} num={2} />
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Dynamic Brain Platform', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.dynamicbrain]} num={2} />
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Brain Transcriptome Database', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.cdtdb]} num={2} />
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Invertebrate Brain Platform', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.invbrain]} num={2} />
                                    </div>
                                </div>
                            </div>
                            <div className="centerRcolumn">
                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Visiome Platform', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.visiome]} num={2} />
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Cerebellar Platform', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.cerebellum]} num={2} />
                                    </div>
                                </div>

                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Mouse Phenotype Database', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.mpdb]} num={2} />
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="blockTitle">
                                        {Functions.mlang('Brain Machine Interface Platform', lang)}
                                    </div>
                                    <div className="blockContent">
                                        <RssBlock lang={lang} sites={[RssFeedSites.bmi]} num={2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                } />
            </Switch>

            <div className="goPageTop">
                <a href="/" onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { e.preventDefault(); window.scrollTo(0, 0); }}>{goPageTop}</a>
            </div>
        </div>
        <Footer lang={lang} />
    </div>;
}

export default Page;
