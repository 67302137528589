import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../../common/lib/PageNotFound';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import BulletinContext from './BulletinContext';
import BulletinStoryItem from './BulletinStoryItem';

interface Props {
    lang: MultiLang;
    context: BulletinContext;
    id: number;
}

const BulletinStory: React.FC<Props> = (props: Props) => {
    const { lang, context, id } = props;
    const story = context.getStory(id);
    if (story === null) {
        return <PageNotFound lang={lang} />;
    }
    const topic = context.getTopic(story.topic_id);
    if (topic === null) {
        return <PageNotFound lang={lang} />;
    }
    const module = context.getModule();
    if (module === null) {
        return <PageNotFound lang={lang} />;
    }
    const titles = [Functions.siteTitle(lang), Functions.mlang(module.name, lang), Functions.mlang(story.title, lang)];
    const stories = context.getStories(story.topic_id, module.stories_of_cat, 0);
    return <div className="story">
        <Helmet>
            <title>{titles.reverse().join(' - ')}</title>
        </Helmet>
        <div style={{ float: 'right' }}>|&nbsp;<Link to={context.getArchiveUrl()}>{Functions.mlang('[en]Archive[/en][ja]アーカイブ[/ja]', lang)}</Link>&nbsp;|</div>
        <div style={{ clear: 'both', height: '0px', visibility: 'hidden' }}></div>
        <div>
            <BulletinStoryItem key={story.id} lang={lang} context={context} story={story} isList={false} />
        </div>
        <div className="item">
            <div className="itemHead">
                <span className="itemTitle">
                    <Link to={context.getTopicUrl(story.topic_id, 5, 0)}>{Functions.mlang(topic.title, lang)}</Link>
                </span>
            </div>
            <div className="itemInfo">
                <ul style={{ textAlign: 'left' }}>
                    {stories.map((story, index) => {
                        return <li key={story.id}><Link to={context.getStoryUrl(story.id)}>{Functions.mlang(story.title, lang)}</Link> ({story.posttime})</li>
                    })}
                </ul>
            </div>
        </div>
    </div>;
};

export default BulletinStory;