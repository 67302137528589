import React from 'react';
import Functions from '../../../functions';
import { ItemJournal, ItemJournalPublisherLocations } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import JournalUtil from './JournalUtil';

class JournalDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemJournal;
        const doi = JournalUtil.getDOI(item.doi);
        const location = ItemJournalPublisherLocations.find((value) => value.type === item.publisher_location) || { type: '', label: '' };
        const fields = [
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: 'ID', value: doi !== '' ? <a href={`https://doi.org/${doi}`}>doi:{doi}</a> : item.doi },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[en]Journal[/en][ja]ジャーナル名[/ja]', value: Functions.mlang(item.journal, lang) },
            { label: '[en]Volume[/en][ja]巻[/ja]', value: Functions.mlang(item.volume, lang) },
            { label: '[en]Publication Year[/en][ja]発行年[/ja]', value: Functions.mlang(item.publication_year.toString(), lang) },
            { label: '[en]Publication Month[/en][ja]発行月[/ja]', value: Functions.mlang(item.publication_month.toString(), lang) },
            { label: '[en]Publisher[/en][ja]出版者[/ja]', value: Functions.mlang(item.publisher, lang) },
            { label: '[en]Location[/en][ja]出版地[/ja]', value: Functions.mlang(location.label, lang) },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Full Text[/en][ja]本文[/ja]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="journal_fulltext_file" rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: 'Rights', value: <ItemTypeField.Rights lang={lang} rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: '[en]Release Date[/en][ja]本公開日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.date} onlyDate={true} /> },
            { label: '[en]Keyword[/en][ja]キーワード[/ja]', value: <ItemTypeField.Repeatable lang={lang} data={item.keyword} /> },
            { label: '[en]Note[/en][ja]補足[/ja]', value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: '[en]Related item[/en][ja]関連アイテム[/ja]', value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
            { label: '[en]Index[/en][ja]インデックス[/ja]', value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: '[en]Item Owner[/en][ja]アイテム所有者[/ja]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[en]Registered Date[/en][ja]登録日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[en]Last Update[/en][ja]最終更新日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[en]Change Log[/en][ja]更新履歴[/ja]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
        ];
        return fields;
    }
}

export default JournalDetail;