import ArticleTop from './ArticleTop';
import ArticleList from './ArticleList';
import ArticleDetail from './ArticleDetail';
import ArticleAdvancedSearch from './ArticleAdvancedSearch';

const ItemTypeArticle = {
    Top: ArticleTop,
    List: ArticleList,
    Detail: ArticleDetail,
    AdvancedSearch: ArticleAdvancedSearch,
};

export default ItemTypeArticle;