import React from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import { ItemArticle } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';
import ArticleUtil from './ArticleUtil';

class ArticleList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = ArticleUtil.label;
        this.icon = ArticleUtil.icon;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemArticle;
        const authors = item.author.map((author, i) => {
            return <span key={i} className="author">{i > 0 && ', '}{Functions.mlang(author.replace(/(?:\(\d+\))+$/, ''), lang)}</span>
        });
        const doi = ArticleUtil.getDOI(item.doi);
        return (
            <div className="journal">
                <div className="title"><Link to={this.url}>{Functions.mlang(item.title, lang)}</Link></div>
                <div className="authors">{authors}</div>
                <div className="journal"><span className="journal_name">{Functions.mlang(item.journal_name, lang)}</span> ({item.publication_year})</div>
                <div className="publisher">{Functions.mlang(item.journal_publisher, lang)}</div>
                {doi !== '' && <div className="doi"><a href={`https://doi.org/${doi}`}>doi:{doi}</a></div>}
            </div>
        );
    }
}

export default ArticleList;