import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import imageSitemapHome from './assets/images/sitemap-home.png';
import imageSitemapNode from './assets/images/sitemap-node.png';
import imageSitemapRoot from './assets/images/sitemap-root.png';
import sitemapData from './assets/sitemap.json';

interface SitemapNodeData {
    label: string;
    link: string;
}

interface SitemapItemData {
    root: SitemapNodeData;
    nodes: SitemapNodeData[];
}

type SitemapData = SitemapItemData[];

interface Props {
    lang: MultiLang;
}

const Sitemap: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Sitemap[/en][ja]サイトマップ[/ja]', lang);
    const sitemap = sitemapData as SitemapData;
    const makeLink = (item: SitemapNodeData): ReactNode => {
        const label = Functions.mlang(item.label, lang);
        return (item.link === '') ? label : <Link to={item.link}>{label}</Link>
    };
    return <div>
        <Helmet><title>{title + ' - ' + Functions.mlang(Config.SITE_TITLE, lang)}</title></Helmet>
        <h1>{title}</h1>
        <NoticeSiteHasBeenArchived lang={lang} />
        <div className="sitemap">
            <div style={{ textAlign: 'right' }}><img src={imageSitemapHome} alt="Sitemap Home" /> <Link to="/">{Functions.mlang('[en]Home[/en][ja]ホーム[/ja]', lang)}</Link></div>
            {sitemap.map((item, i) => {
                return <div key={'r' + i} style={{ width: '310px', margin: '10px', float: 'left', whiteSpace: 'nowrap' }}>
                    <div><img src={imageSitemapRoot} alt="Sitemap Root" /> {makeLink(item.root)}</div>
                    {item.nodes.map((node, j) => {
                        return <div key={'n' + j}><img src={imageSitemapNode} alt="Sitemap Node" /> {makeLink(node)}</div>;
                    })}
                </div>;
            })}
            <div style={{ clear: 'both' }}>&nbsp;</div>
        </div>
    </div>;
}

export default Sitemap;
