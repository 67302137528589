import { MultiLang } from '../../config';
import BulletinContext, { BulletinStoryData } from './BulletinContext';

class BulletinUtils {

    private static contexts: Map<string, BulletinContext> = new Map<string, BulletinContext>();

    private constructor() {
        // blcok to access from outside of this class
    }

    public static async getContext(mydirname: string): Promise<BulletinContext> {
        let context = this.contexts.get(mydirname);
        if (typeof context === 'undefined') {
            context = new BulletinContext(mydirname);
            await context.initialize();
            this.contexts.set(mydirname, context);
        }
        return context;
    }

    public static getStoryText(story: BulletinStoryData, isList: boolean, lang: MultiLang): { text: string, rchars: number } {
        const pages = story.text[lang].split('[pagebreak]');
        return isList ? {
            text: pages[0],
            rchars: pages.filter((value, index) => index !== 0).join('<br /><br />').length
        } : {
                text: pages.join('<br /><br />'),
                rchars: 0
            };
    }

    public static getMonthLabel(month: number, lang: MultiLang): string {
        const label = {
            en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            ja: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        }
        return label[lang][month - 1];
    }

}

export default BulletinUtils;