import React, { Component, ReactNode } from 'react';
import { MultiLang } from '../../../config';
import Functions from '../../../functions';
import { Item } from '../../lib/ItemUtil';

export interface DetailBaseField {
    label: string;
    value: ReactNode;
}

export interface DetailBaseProps {
    lang: MultiLang;
    item: Item;
    isBaseItem?: boolean;
}

class DetailBase extends Component<DetailBaseProps> {

    getFields(): DetailBaseField[] {
        return [];
    }

    render() {
        const { lang, isBaseItem } = this.props;
        const renderField = (label: string, value: ReactNode, idx: number) => {
            const evenodd = idx % 2 === 0 ? 'even' : 'odd';
            return (
                <tr key={idx}>
                    <td className="head">{Functions.mlang(label, lang)}</td>
                    <td className={evenodd}>{value}</td>
                </tr>
            )
        }
        let idx = 1;
        const elements:JSX.Element[] = [];
        this.getFields().forEach((field) => {
            if (typeof isBaseItem === 'undefined' || isBaseItem === false || (field.label !== 'Index' && field.label !== '[en]Related to[/en][ja]関連アイテム[/ja]')) {
                idx++;
                elements.push(renderField(field.label, field.value, idx));    
            }
        });
        return (
            <table className="outer itemDetail">
                <tbody>
                    {elements}
                </tbody>
            </table>
        );
    }
}

export default DetailBase;