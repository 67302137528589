import React, { ChangeEvent, Component, FormEvent } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Config, { MultiLang } from '../../config';
import Functions from '../../functions';
import ItemUtil from '../lib/ItemUtil';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    type: string;
    keyword: string;
    path: string;
}

class XooNIpsSearchBlock extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const { location } = props;
        this.state = {
            type: 'all',
            keyword: '',
            path: location.pathname + location.search + location.hash,
        };
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const { location } = nextProps;
        const path = location.pathname + location.search + location.hash
        if (path !== prevState.path) {
            const { type, keyword } = ItemUtil.getSearchKeywordByQuery(location.search);
            return { type, keyword, path };
        }
        return null;
    }

    handleChangeKeyword(event: ChangeEvent<HTMLInputElement>) {
        const keyword = event.target.value.trim();
        this.setState({ keyword });
    }

    handleChangeType(event: ChangeEvent<HTMLSelectElement>) {
        const type = event.target.value;
        this.setState({ type });
    }

    handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url = ItemUtil.getSearchByKeywordUrl(this.state.type, this.state.keyword);
        this.props.history.push(url);
    }

    render() {
        const { lang } = this.props;
        const options = [
            { value: 'all', label: '[en]All[/en][ja]全て[/ja]' },
            { value: 'basic', label: '[en]Title & Keyword[/en][ja]タイトル＆キーワード[/ja]' },
        ];

        Config.XOONIPS_ITEMTYPES.forEach((type) => {
            options.push({ value: type, label: Functions.pascalCase(type) })
        });
        return (
            <form onSubmit={this.handleSubmit}>
                <input style={{ width: '170px' }} type="text" value={this.state.keyword} onChange={this.handleChangeKeyword} />
                &nbsp;&nbsp;
                <select value={this.state.type} onChange={this.handleChangeType}>
                    {options.map((option) => {
                        return <option key={option.value} value={option.value}>{Functions.mlang(option.label, lang)}</option>;
                    })}
                </select>
                <br />
                <input className="formButton" type="submit" value="Search" />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/modules/xoonips/search.php?op=advanced">{Functions.mlang('[en]Advanced[/en][ja]詳細検索[/ja]', lang)}</Link>
            </form>
        );
    }
}

export default withRouter(XooNIpsSearchBlock);