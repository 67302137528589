import PicoContext from './PicoContext';

class PicoUtils {

    private static contexts: Map<string, PicoContext> = new Map<string, PicoContext>();

    private constructor() {
        // blcok to access from outside of this class
    }

    public static getContext(dirname: string, showTitle: boolean): PicoContext {
        let context = this.contexts.get(dirname);
        if (typeof context === 'undefined') {
            context = new PicoContext(dirname, showTitle);
            this.contexts.set(dirname, context);
        }
        return context;
    }

}

export default PicoUtils;
