import TopBase, { TopBaseProps } from '../lib/TopBase';
import GeneralDataUtil from './GeneralDataUtil';

class GeneralDataTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = 'generaldata';
        this.label = 'General Data and Documents';
        this.icon = GeneralDataUtil.iconImage;
        this.description = '[en]General Data and Documents[/en][ja]汎用データ・ドキュメント[/ja]';
    }
}

export default GeneralDataTop;