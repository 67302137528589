import TopBase, { TopBaseProps } from '../lib/TopBase';
import JournalUtil from './JournalUtil';

class JournalTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = JournalUtil.type;
        this.label = JournalUtil.label;
        this.icon = JournalUtil.icon;
        this.description = JournalUtil.description;
    }
}

export default JournalTop;