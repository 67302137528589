import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import BulletinContext from './BulletinContext';

interface Props {
    lang: MultiLang;
    context: BulletinContext;
    topicId: number;
    limit: number;
    start: number;
}

const BulletinStoriesPageNavi: React.FC<Props> = (props: Props) => {
    const { lang, context, topicId, limit, start } = props;
    const total = context.countStories(topicId);
    if (start > total) {
        return null;
    }
    const now = Math.ceil((start + 1) / limit);
    const min = 1;
    const max = Math.ceil(total / limit);
    const prev = now === 1 ? 0 : now - 1;
    const next = now === max ? 0 : now + 1;
    let rangeMin = now - 3;
    if (rangeMin < 2) {
        rangeMin = 2;
    }
    let rangeMax = now + 3;
    if (rangeMax > max - 1) {
        rangeMax = max - 1;
    }
    const link = (num: number, el?: JSX.Element) => {
        const label = el || num;
        const url = context.getTopicUrl(topicId, limit, (num - 1) * limit);
        return num === now ? <span className="current">{label}</span> : <Link to={url}>{label}</Link>
    }
    const items: JSX.Element[] = [];
    if (prev !== 0) {
        items.push(link(prev, <span className="prev">&laquo;</span>));
    }
    items.push(link(min));
    if (now - min > 4) {
        items.push(<>...</>);
    }
    for (let range = rangeMin; range <= rangeMax; range++) {
        items.push(link(range));
    }
    if (max - now > 4) {
        items.push(<>...</>);
    }
    items.push(link(max));
    if (next !== 0) {
        items.push(link(next, <span className="next">&raquo;</span>));
    }
    const rangeS = start + 1;
    const rangeE = (start + limit) < total ? start + limit : total;

    return <nav className="pagenavi">
        <span className="label">{Functions.mlang(`[en]${total} Hits (${rangeS}-${rangeE})[/en][ja]全${total}件 (${rangeS}-${rangeE}件目)[/ja]`, lang)}</span>
        {total > limit && <span className="pages">{items.map((el, index) => <Fragment key={index}>{index !== 0 && <>&nbsp;</>}{el}</Fragment>)}</span>}
    </nav>;
};

export default BulletinStoriesPageNavi;