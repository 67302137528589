import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    className?: string;
}

const langResources = {
    en: 'English',
    ja: 'Japanese',
};

const LangLink = (props: Props) => {
    const { lang, className } = props;
    const params = new URLSearchParams(props.location.search);
    const flagLang = lang === 'en' ? 'ja' : 'en';
    params.set('ml_lang', flagLang);
    const url = props.location.pathname + '?' + params.toString();
    return <Link className={className} to={url}><span>{langResources[flagLang]}</span></Link>;
}


export default withRouter(LangLink);
