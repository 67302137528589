import JournalTop from './JournalTop';
import JournalList from './JournalList';
import JournalDetail from './JournalDetail';
import JournalAdvancedSearch from './JournalAdvancedSearch';

const ItemTypeJournal = {
    Top: JournalTop,
    List: JournalList,
    Detail: JournalDetail,
    AdvancedSearch: JournalAdvancedSearch,
};

export default ItemTypeJournal;