import loki from 'lokijs';
import indexesJson from '../assets/tree.json';

export interface Index {
    id: number;
    title: string;
    numOfItems: number;
    parentId: number;
    weight: number;
}

export const INDEX_ID_ROOT = 1;
export const INDEX_ID_PUBLIC = 2;

interface IndexData {
    id: number;
    title: string;
    num_of_items: number;
    children: IndexData[];
}
type IndexesData = IndexData[];

class IndexUtil {

    private database: loki;
    private indexes: Collection<Index>;

    constructor(json: IndexesData) {
        this.database = new loki('database');
        this.indexes = this.database.addCollection('indexes');
        this.load(json);
    }

    load(json: IndexesData): void {
        const store = (indexesData: IndexesData, parentId: number) => {
            indexesData.forEach((indexData: IndexData, idx) => {
                const entry: Index = {
                    id: indexData.id,
                    title: indexData.title,
                    numOfItems: indexData.num_of_items,
                    parentId: parentId,
                    weight: idx,
                };
                this.indexes.insert(entry);
                store(indexData.children, indexData.id)
            });
        }
        store(json, INDEX_ID_ROOT);
    }

    getUrl(id: number): string {
        return '/modules/xoonips/list.php?index_id=' + String(id);
    }

    get(indexId: number): Index | null {
        const filter = {
            'id': indexId,
        }
        const res = this.indexes.findOne(filter);
        return res;
    }

    getChildren(indexId: number): Index[] {
        const filter = {
            'parentId': indexId,
        }
        const res = this.indexes.chain().find(filter).simplesort('weight').data();
        return res;
    }

    countChildren(indexId: number): number {
        const filter = {
            'parentId': indexId,
        }
        const res = this.indexes.count(filter);
        return res;
    }

    getParents(parentId: number): Index[] {
        let parents: Index[] = [];
        const loop = (parentId: number) => {
            if (parentId !== INDEX_ID_ROOT) {
                const parent = this.get(parentId);
                if (parent !== null) {
                    loop(parent.parentId);
                    parents.push(parent);
                }
            }
        }
        loop(parentId);
        return parents;
    }
}

export default new IndexUtil(indexesJson);