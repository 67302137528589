import React from 'react';
import { ItemBasicLang, ItemBasicLangs } from '../../../lib/ItemUtil';
import { MultiLang } from '../../../../config';
import Functions from '../../../../functions';

interface Props {
    lang: MultiLang;
    itemLang: ItemBasicLang;
}

const Language = (props: Props) => {
    const { lang, itemLang } = props;
    const xlang = ItemBasicLangs.find((value) => value.type === itemLang);
    if (typeof xlang === 'undefined') {
        return null;
    }
    return (<span>{Functions.mlang(xlang.label, lang)}</span>);
}

export default Language;