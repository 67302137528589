import icon from './icon.png';

const JournalUtil = {
    type: 'journal',
    label: 'Journal',
    description: '[en]Journal[/en][ja]ジャーナル・定期刊行物[/ja]',
    icon,
    getDOI: (id:string): string => {
        if (/^aini201[7-8]$/.test(id)) {
            return '10.14931/' + id;
        }
        return '';
    }
}

export default JournalUtil;