import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import Config, { MultiLang } from '../config';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}

const message = {
    ja: <p>
        INCF日本ノードの事務局は2005年に理研BSI神経情報基盤センターとして設置され、2018年4月より理研CBS神経情報基盤開発ユニットに引き継がれ、国内外の多くの皆様のご協力を得て、運営して参りました。
        CBSにおけるニューロインフォマティクスの新たな方向性を探る中で、神経情報基盤開発ユニットは日本ノード事務局の任を2019年3月末日にて終了することとなりました。
    </p>,
    en: <p>
        Secretariat of the INCF Japan Node (J-Node) was launched in 2005 as the starting of Neuroinformatics Japan Center, RIKEN Brain Science Institute (BSI).
        In April 2018,  J-Node secretariat was inherited to Neuroinformatics Unit, RIKEN Center for Brain Science (CBS).
        However, based on reconsideration at CBS, closure of J-Node secretariat at the end of FY 2018 has been decided.
        Accordingly, the collaborative development of neuroinformatics platforms will finish.
        Each J-Node related site is going to be transferred to a new managing form.
    </p>
};

const ContactUs: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Contact Us[/en][ja]お問い合わせ[/ja]', lang);
    return <div>
        <Helmet><title>{title + ' - ' + Functions.mlang(Config.SITE_TITLE, lang)}</title></Helmet>
        <h1>{title}</h1>
        <NoticeSiteHasBeenArchived lang={lang} />
        {message[lang]}
        <p>
            {Functions.mlang('[en]Please see the details[/en][ja]詳細についてはこちらをご覧ください[/ja]', lang)}: <Link to="/modules/news/index.php?page=article&storyid=363">{Functions.mlang('[en]Closure of Secretariat of the INCF Japan Node[/en][ja]INCF日本ノード事務局終了のお知らせ[/ja]', lang)}</Link>
        </p>
        <p>
            {Functions.mlang('[en]Thank you for your understanding.[/en][ja]皆様よりの長年の多大なご支援、深く感謝申し上げます。[/ja]', lang)}
        </p>
    </div>;
}

export default ContactUs;
