import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import BulletinContext from './BulletinContext';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    context: BulletinContext;
    topicId: number;
    num: number;
}

interface State {
    path: string;
    topicId: number;
    num: number;
}

class BulletinStoriesSelect extends React.Component<Props, State> {

    private STORY_NUMS: ReadonlyArray<number> = [5, 10, 15, 20, 30];

    constructor(props: Props) {
        super(props);
        const {topicId, num, location} = props;
        this.state = {
            path: location.pathname + location.search + location.hash,
            topicId,
            num,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectTopic = this.handleSelectTopic.bind(this);
        this.handleSelectNum = this.handleSelectNum.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const { topicId, num, location } = nextProps;
        const path = location.pathname + location.search + location.hash;
        if (prevState.path !== path) {
            return {
                path,
                topicId,
                num
            };
        }
        return null;
    }

    handleSelectTopic(event: React.ChangeEvent<HTMLSelectElement>) {
        const topicId = parseInt(event.target.value, 10);
        this.setState({ topicId })
    }

    handleSelectNum(event: React.ChangeEvent<HTMLSelectElement>) {
        const num = parseInt(event.target.value, 10);
        this.setState({ num })
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        const { context, history } = this.props;
        event.preventDefault();
        if (context !== null) {
            const url = context.getTopicUrl(this.state.topicId, this.state.num, 0);
            history.push(url);
        }
    }

    render() {
        const { lang, context } = this.props;
        const topics = context.getTopics();
        return <div style={{ textAlign: 'center' }}>
            <form onSubmit={this.handleSubmit}>
                <select value={this.state.topicId} onChange={this.handleSelectTopic}>
                    <option value="0">----</option>
                    {topics.map(topic => 
                        <option key={topic.id} value={topic.id}>{Functions.mlang(topic.title, lang)}</option>
                    )}
                </select>
                &nbsp;
                <select value={this.state.num} onChange={this.handleSelectNum}>
                    {this.STORY_NUMS.map((num) => <option key={num} value={num}>{num}</option>)}
                </select>
                &nbsp;
                <input type="submit" value={Functions.mlang('[en]Go![/en][ja]送信[/ja]', lang)} />
            </form>
        </div>;
    }
}

export default withRouter(BulletinStoriesSelect);