import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import LangLink from '../common/lib/LangLink';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import ItemUtil from '../xoonips/lib/ItemUtil';
import imageFacebookIcon from './assets/images/facebook-icon.png';
import imageFlickrIcon from './assets/images/flickr-icon.png';
import imageIncfLogo from './assets/images/incf-logo.png';
import imageSitemapIcon from './assets/images/sitemap.png';
import imageTwitterIcon from './assets/images/twitter-icon.png';
import imageSearchButton from './assets/images/search.png';
import { HashLink } from 'react-router-hash-link';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    keyword: string;
    menuid: number;
}

class Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            keyword: '',
            menuid: 0
        };
        this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleChangeKeyword(event: React.ChangeEvent<HTMLInputElement>) {
        const keyword = event.target.value;
        this.setState({ keyword });
    }

    handleOnClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        const keyword = this.state.keyword.trim();
        if (keyword !== '') {
            const url = ItemUtil.getSearchByKeywordUrl('all', this.state.keyword);
            this.props.history.push(url);
        }
    }

    setMenuId(menuid: number) {
        this.setState({ menuid });
    }

    render() {
        const { lang } = this.props;
        return (
            <header id="header">
                <div className="top">
                    <Link className="logo" to="/"><span className="hidden">{Config.SITE_TITLE}</span></Link>
                    <div className="utility">
                        <div className="socialmedia">
                            <a href="https://twitter.com/neuroinf_jp" target="_blank" rel="noopener noreferrer"><img src={imageTwitterIcon} alt="Twitter" title="Twitter" /></a>
                            <a href="https://www.facebook.com/INCFJapanNode" target="_blank" rel="noopener noreferrer"><img src={imageFacebookIcon} alt="Facebook" title="Fackbook" /></a>
                            <a href="https://www.flickr.com/photos/137465753@N08/albums" target="_blank" rel="noopener noreferrer"><img src={imageFlickrIcon} alt="Flickr" title="Flickr" /></a>
                        </div>
                        <Link className="sitemap" to="/sitemap"><img src={imageSitemapIcon} alt="Sitemap" title="Sitemap" /></Link>
                        <span className="language"><LangLink lang={lang} /></span>
                        <a className="incf" href="https://www.incf.org/" target="_blank" rel="noopener noreferrer"><img src={imageIncfLogo} alt="INCF - International Neuroinformatics Coordinating Facility" title="INCF - International Neuroinformaitcs Coordinating Facility" /></a>
                    </div>
                </div>
                <nav className="menubar">
                    <ul className="mainmenu">
                        <li><Link to="/">{Functions.mlang('[en]Home[/en][ja]ホーム[/ja]', lang)}</Link></li>
                        <li onMouseEnter={() => this.setMenuId(1)} onMouseLeave={() => this.setMenuId(0)}><span>{Functions.mlang('[en]About us[/en][ja]J-Node について[/ja]', lang)}</span>
                            {1 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/aboutus/incf.html">{Functions.mlang('[en]INCF[/en][ja]ニューロインフォマティクス国際統合機構 INCF[/ja]', lang)}</Link>
                                    <Link className="item" to="/aboutus/j-node.html">{Functions.mlang('[en]INCF Jpan Node[/en][ja]INCF 日本ノード[/ja]', lang)}</Link>
                                    <Link className="item" to="/joinus/index.html">{Functions.mlang('[en]Join Us[/en][ja]参加方法[/ja]', lang)}</Link>
                                    <Link className="item" to="/aboutus/history.html">{Functions.mlang('[en]History[/en][ja]沿革[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                        <li onMouseEnter={() => this.setMenuId(2)} onMouseLeave={() => this.setMenuId(0)}><span>{Functions.mlang('[en]News and Topics[/en][ja]ニュース[/ja]', lang)}</span>
                            {2 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/news/index.php?storytopic=2">{Functions.mlang('[en]Topics/Articles[/en][ja]トピックス[/ja]', lang)}</Link>
                                    <Link className="item" to="/news/index.php?storytopic=3">{Functions.mlang('[en]Meeting/Event[/en][ja]会議/イベント[/ja]', lang)}</Link>
                                    <Link className="item" to="/news/index.php?storytopic=7">{Functions.mlang('[en]From J-Node Secretariat[/en][ja]事務局からのお知らせ[/ja]', lang)}</Link>
                                    <Link className="item" to="/news/index.php?storytopic=4">{Functions.mlang('[en]Newsletters[/en][ja]ニュースレター[/ja]', lang)}</Link>
                                    <Link className="item" to="/news/index.php?storytopic=5">{Functions.mlang('[en]J-Node Update[/en][ja]J-Nodeサイトの更新情報[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                        <li onMouseEnter={() => this.setMenuId(3)} onMouseLeave={() => this.setMenuId(0)}><span>{Functions.mlang('[en]Resources[/en][ja]リソース[/ja]', lang)}</span>
                            {3 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/resource/platform.html">{Functions.mlang('[en]J-Node Platform[/en][ja]日本ノードプラットフォーム[/ja]', lang)}</Link>
                                    <HashLink className="item" to="/resource/platform.html#link-pf-update">{Functions.mlang('[en]Platform Updates[/en][ja]プラットフォーム更新情報[/ja]', lang)}</HashLink>
                                    <Link className="item" to="/resource/software.html">{Functions.mlang('[en]Infrastructure Software[/en][ja]基盤ソフトウェア[/ja]', lang)}</Link>
                                    <Link className="item" to="/publication/index.html">{Functions.mlang('[en]Publication[/en][ja]出版物・刊行物[/ja]', lang)}</Link>
                                    <Link className="item" to="/tutorial/index.html">{Functions.mlang('[en]Tutorial[/en][ja]チュートリアル[/ja]', lang)}</Link>
                                    <Link className="item" to="/link/index.html">{Functions.mlang('[en]Related Link[/en][ja]関連リンク[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                        <li onMouseEnter={() => this.setMenuId(4)} onMouseLeave={() => this.setMenuId(0)}><span>{Functions.mlang('[en]Program[/en][ja]プログラム[/ja]', lang)}</span>
                            {4 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/program/nijc-program.html">{Functions.mlang('[en]Overview[/en][ja]プログラム概要[/ja]', lang)}</Link>
                                    <Link className="item" to="/program/platforms.html">{Functions.mlang('[en]Neuroinformatics Platforms[/en][ja]プラットフォーム開発運用[/ja]', lang)}</Link>
                                    <Link className="item" to="/program/j-node-hackathon.html">{Functions.mlang('[en]Hackathon[/en][ja]ハッカソン[/ja]', lang)}</Link>
                                    <Link className="item" to="/program/workshop-aini.html">{Functions.mlang('[en]Workshop AINI[/en][ja]国債ワークショップAINI[/ja]', lang)}</Link>
                                    <Link className="item" to="/program/doi.html">{Functions.mlang('[en]DOI[/en][ja]DOI[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                        <li><Link to="/contactus">{Functions.mlang('[en]Contact Us[/en][ja]お問い合わせ[/ja]', lang)}</Link></li>
                    </ul>
                    <div className="search_box">
                        <input type="text" value={this.state.keyword} onChange={this.handleChangeKeyword} />
                        <a href="/" onClick={this.handleOnClick}><img src={imageSearchButton} alt="Search" /></a>
                    </div>
                </nav>
            </header>
        );

    }
}

export default withRouter(Header);
