import React from 'react';
import Functions from '../../../functions';
import { ItemBasicLangs } from '../../lib/ItemUtil';
import AdvancedSearchBase, { AdvancedSearchBaseProps } from '../lib/AdvancedSearchBase';

class GeneralDataAdvancedSearch extends AdvancedSearchBase {

    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = 'generaldata';
        this.title = 'General Data and Documents';
        const now = new Date();
        const year = String(now.getFullYear());
        const month = String(now.getMonth() + 1);
        const mday = String(now.getDate());
        this.state.values['doi'] = '';
        this.state.values['title'] = '';
        this.state.values['description'] = '';
        this.state.values['lang'] = '';
        this.state.values['file.generaldata_file.original_file_name'] = '';
        this.state.values['creator'] = '';
        this.state.values['affiliation'] = '';
        this.state.values['publication_year'] = year;
        this.state.values['publication_month'] = month;
        this.state.values['publication_mday'] = mday;
        this.state.values['publisher'] = '';
        this.state.values['version'] = '';
        this.state.values['format'] = '';
        this.state.values['variation'] = '';
        this.state.values['relation'] = '';
        this.state.values['keyword'] = '';
        this.setIgnoreKey('publication_year');
        this.setIgnoreKey('publication_month');
        this.setIgnoreKey('publication_mday');
    }

    renderEdition() {
        const { lang } = this.props;
        return <table>
            <tbody>
                <tr>
                    <td>{Functions.mlang('[en]Version[/en][ja]バージョン[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('version', 30)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Format[/en][ja]フォーマット[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('format', 30)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Variation[/en][ja]バリエーション[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('variation', 30)}</td>
                </tr>
            </tbody>
        </table>;
    }

    getRows() {
        const rows = [
            { label: 'ID', value: this.renderFieldInputText('doi', 50) },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: this.renderFieldInputText('title', 50) },
            { label: '[en]Description[/en][ja]概要[/ja]', value: this.renderFieldInputText('description', 50) },
            { label: '[en]Language[/en][ja]言語[/ja]', value: this.renderFieldSelect('lang', ItemBasicLangs) },
            { label: '[en]Files[/en][ja]ファイル[/ja]', value: this.renderFieldInputText('file.generaldata_file.original_file_name', 50) },
            { label: '[en]Creator[/en][ja]作成者[/ja]', value: this.renderFieldInputText('creator', 50) },
            { label: '[en]Affiliation[/en][ja]所属[/ja]', value: this.renderFieldInputText('affiliation', 50) },
            { label: '[en]Publication Date[/en][ja]発行日[/ja]', value: this.renderFieldDate('', 'publication_year', 'publication_month', 'publication_mday') },
            { label: '[en]Publisher[/en][ja]発行者[/ja]', value: this.renderFieldInputText('publisher', 50) },
            { label: '[en]Edition[/en][ja]版[/ja]', value: this.renderEdition() },
            { label: '[en]Relation[/en][ja]関連リンク[/ja]', value: this.renderFieldInputText('relation', 50) },
            { label: '[en]Keywords[/en][ja]キーワード[/ja]', value: this.renderFieldInputText('keyword', 50) },
        ];
        return rows;
    }
}

export default GeneralDataAdvancedSearch;