import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import imageLinksAini2018 from '../assets/images/links-aini2018.png';
import imageLinksHakcathon2018Sep from '../assets/images/links-hackathon2018sep.png';
import imageLinksJnodePf from '../assets/images/links-jnodepf.png';
import imageLinksNewsletterE from '../assets/images/links-newsletter-e.png';
import imageLinksNewsletterJ from '../assets/images/links-newsletter-j.png';
import imageLinksPfReportE from '../assets/images/links-pfreport-e.png';
import imageLinksPfReportJ from '../assets/images/links-pfreport-j.png';

interface Props {
    lang: MultiLang;
}

const LinkBannersBlock: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const links = [
        { name: 'AINI 2018', image: imageLinksAini2018, link: '/aini2018/' },
        { name: 'J-Node Hackathon 2018 September', image: imageLinksHakcathon2018Sep, link: '/hackathon2018/september.html' },
        { name: 'J-Node Platform', image: imageLinksJnodePf, link: '/resource/platform.html' },
        { name: 'J-Node Newsletter', image: lang === 'en' ? imageLinksNewsletterE : imageLinksNewsletterJ, link: '/modules/news/index.php?storytopic=4' },
        { name: 'J-Node Platform Report', image: lang === 'en' ? imageLinksPfReportE : imageLinksPfReportJ, link: '/publication' }
    ];
    return <div id="link-banners">
        {links.map((value, index) => {
            const { name, image, link } = value;
            return <div key={index}>
                <Link to={link}><img src={image} alt={name} /></Link>
            </div>;
        })}
    </div>;
};

export default LinkBannersBlock;