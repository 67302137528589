import TopBase, { TopBaseProps } from '../lib/TopBase';
import ArticleUtil from './ArticleUtil';

class ArticleTop extends TopBase {

    constructor(props: TopBaseProps) {
        super(props);
        this.type = ArticleUtil.type;
        this.label = ArticleUtil.label;
        this.icon = ArticleUtil.icon;
        this.description = ArticleUtil.description;
    }
}

export default ArticleTop;