import React from 'react';
import { Link } from 'react-router-dom';
import Functions from '../../../functions';
import ItemUtil, { ItemGeneralData } from '../../lib/ItemUtil';
import ListBase, { ListBaseProps } from '../lib/ListBase';
import GeneralDataUtil from './GeneralDataUtil';
import FileSize from '../lib/field/FileSize';

class GeneralDataList extends ListBase {

    constructor(props: ListBaseProps) {
        super(props);
        this.label = 'General Data';
        this.icon = GeneralDataUtil.iconImage;
    }

    renderIcon() {
        const { lang } = this.props;
        const item = this.props.item as ItemGeneralData;
        const data = item.file.filter((value) => {
            return value.file_type_name === 'preview';
        });
        let imgSrc = this.icon;
        let imgAlt = this.label;
        if (data.length > 0) {
            const preview = data.pop();
            if ('undefined' !== typeof preview) {
                imgSrc = ItemUtil.getPreviewFileUrl(preview);
                if ('' !== preview.caption) {
                    imgAlt = Functions.mlang(preview.caption, lang);
                }
            }
        }
        return <img src={imgSrc} alt={imgAlt} />;
    }

    renderBody() {
        const { lang } = this.props;
        const item = this.props.item as ItemGeneralData;
        const authors = item.creator.map((author, i) => {
            return <span key={i} className="author">{i > 0 && ', '}{Functions.mlang(author, lang)}</span>
        });
        const files = item.file.filter((value) => value.file_type_name === 'generaldata_file').map((value) => {
            return <div key={value.file_id} className="file">- <span className="name">{value.original_file_name}</span> <span>(<FileSize lang={lang} size={value.file_size} />)</span></div>
        });
        const pubdate = new Date(item.publication_year, item.publication_month - 1, item.publication_mday);
        return (
            <div className="item">
                <div className="title"><Link to={this.url}>{Functions.mlang(item.title, lang)}</Link></div>
                <div className="authors">{authors}</div>
                <div className="files">{files}</div>
                <div className="publicationDate">{Functions.formatDate(pubdate.getTime() / 1000, 'MMM Do YYYY')}</div>
            </div>
        );
    }
}

export default GeneralDataList;