import React, { Component } from 'react';
import { MultiLang } from '../config';
import Functions from '../functions';
import ItemUtil, { SearchCallbackFunc, SortCondition } from './lib/ItemUtil';
import XoonipsListItem from './lib/XoonipsListItem';

interface Props {
    lang: MultiLang;
    type: string;
    keyword: string;
}

class XoonipsSearchByKeyword extends Component<Props> {

    constructor(props: Props) {
        super(props);
        this.searchFunc = this.searchFunc.bind(this);
    }

    getUrl() {
        const { type, keyword } = this.props;
        return ItemUtil.getSearchByKeywordUrl(type, keyword);
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        const { type, keyword } = this.props;
        if (keyword === '') {
            const res = { total: 0, data: [] };
            func(res);
        } else {
            ItemUtil.getListByKeyword(type, keyword, condition, func);
        }
    }

    render() {
        const { lang, keyword } = this.props;
        const baseUrl = this.getUrl();
        return (
            <div className="list">
                <h3>{Functions.mlang('[en]Listing item[/en][ja]アイテム一覧[/ja]', lang)}</h3>
                <p>{Functions.mlang('[en]Search Keyword[/en][ja]検索キーワード[/ja]', lang)} : {keyword}</p>
                <XoonipsListItem lang={lang} url={baseUrl} search={this.searchFunc} />
            </div>
        );
    }
}

export default XoonipsSearchByKeyword;
