import React from 'react';
import Functions from '../../../functions';
import { ItemBasicLangs } from '../../lib/ItemUtil';
import AdvancedSearchBase, { AdvancedSearchBaseProps } from '../lib/AdvancedSearchBase';
import ArticleUtil from './ArticleUtil';

class ArticleAdvancedSearch extends AdvancedSearchBase {

    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = ArticleUtil.type;
        this.title = ArticleUtil.label;
        const now = new Date();
        const year = String(now.getFullYear());
        const month = String(now.getMonth() + 1);
        const mday = String(now.getDate());
        this.state.values['doi'] = '';
        this.state.values['title'] = '';
        this.state.values['author'] = '';
        this.state.values['affiliation'] = '';
        this.state.values['journal_name'] = '';
        this.state.values['journal_volume'] = '';
        this.state.values['publication_year'] = '';
        this.state.values['publication_month'] = '';
        this.state.values['journal_page'] = '';
        this.state.values['journal_publisher'] = '';
        this.state.values['meeting_name'] = '';
        this.state.values['meeting_place'] = '';
        this.state.values['meeting_date_from_year'] = year;
        this.state.values['meeting_date_from_month'] = month;
        this.state.values['meeting_date_from_mday'] = mday;
        this.state.values['meeting_date_to_year'] = year;
        this.state.values['meeting_date_to_month'] = month;
        this.state.values['meeting_date_to_mday'] = mday;
        this.state.values['meeting_program_no'] = '';
        this.state.values['journal_page'] = '';
        this.state.values['journal_page'] = '';
        this.state.values['journal_page'] = '';
        this.state.values['lang'] = '';
        this.state.values['file.article_fulltext_file.original_file_name'] = '';
        this.state.values['date_from_year'] = year;
        this.state.values['date_from_month'] = month;
        this.state.values['date_from_mday'] = mday;
        this.state.values['date_to_year'] = year;
        this.state.values['date_to_month'] = month;
        this.state.values['date_to_mday'] = mday;
        this.state.values['keyword'] = '';
        this.setIgnoreKey('meeting_date_from_year');
        this.setIgnoreKey('meeting_date_from_month');
        this.setIgnoreKey('meeting_date_from_mday');
        this.setIgnoreKey('meeting_date_to_year');
        this.setIgnoreKey('meeting_date_to_month');
        this.setIgnoreKey('meeting_date_to_mday');
        this.setIgnoreKey('date_from_year');
        this.setIgnoreKey('date_from_month');
        this.setIgnoreKey('date_from_mday');
        this.setIgnoreKey('date_to_year');
        this.setIgnoreKey('date_to_month');
        this.setIgnoreKey('date_to_mday');
    }

    renderJournal() {
        const { lang } = this.props;
        return <table>
            <tbody>
                <tr>
                    <td>{Functions.mlang('[en]Name[/en][ja]名称[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('journal_name', 50)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Volume[/en][ja]巻[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('journal_volume', 50)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Publication Year[/en][ja]発行年[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('publication_year', 10)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Publication Month[/en][ja]発行月[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('publication_month', 10)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Page[/en][ja]ページ[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('journal_page', 10)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Publisher[/en][ja]出版者[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('journal_publisher', 50)}</td>
                </tr>
            </tbody>
        </table>;
    }

    renderMeeting() {
        const { lang } = this.props;
        return <table>
            <tbody>
                <tr>
                    <td>{Functions.mlang('[en]Name[/en][ja]名称[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('meeting_name', 50)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Place[/en][ja]場所[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('meeting_place', 50)}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Date (From)[/en][ja]日付 (自)[/ja]', lang)}</td>
                    <td>{this.renderFieldDate('', 'meeting_date_from_year', 'meeting_date_from_month', 'meeting_date_from_mday')}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Date (To)[/en][ja]日付 (至)[/ja]', lang)}</td>
                    <td>{this.renderFieldDate('', 'meeting_date_to_year', 'meeting_date_to_month', 'meeting_date_to_mday')}</td>
                </tr>
                <tr>
                    <td>{Functions.mlang('[en]Program Num[/en][ja]発表番号[/ja]', lang)}</td>
                    <td>{this.renderFieldInputText('meeting_program_no', 50)}</td>
                </tr>
            </tbody>
        </table>;
    }

    renderDate() {
        return (
            <>
                <div>
                    {this.renderFieldDate('From', 'date_from_year', 'date_from_month', 'date_from_mday')}

                </div>
                <div>
                    {this.renderFieldDate('To', 'date_to_year', 'date_to_month', 'date_to_mday')}
                </div>
            </>
        );
    }
    
    getRows() {
        const rows = [
            { label: 'ID', value: this.renderFieldInputText('doi', 50) },
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: this.renderFieldInputText('title', 50) },
            { label: '[en]Author[/en][ja]著者[/ja]', value:  this.renderFieldInputText('author', 50)  },
            { label: '[en]Affiliation[/en][ja]所属[/ja]', value:  this.renderFieldInputText('affiliation', 50) },
            { label: '[en]Journal[/en][ja]ジャーナル[/ja]', value: this.renderJournal() },
            { label: '[en]Meeting[/en][ja]会議[/ja]', value: this.renderMeeting() },
            { label: '[en]Language[/en][ja]言語[/ja]', value: this.renderFieldSelect('lang', ItemBasicLangs) },
            { label: '[en]Full Text[/en][ja]本文ファイル[/ja]', value: this.renderFieldInputText('file.article_fulltext_file.original_file_name', 50) },
            { label: '[en]Publication Date[/en][ja]発行日[/ja]', value: this.renderDate() },
            { label: '[en]Keywords[/en][ja]キーワード[/ja]', value: this.renderFieldInputText('keyword', 50) },
        ];
        return rows;
    }
}

export default ArticleAdvancedSearch;