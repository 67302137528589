import axios from 'axios';
import loki from 'lokijs';

export interface PicoModuleData {
    name: string;
    dirname: string;
    message: string;
    show_menuinmoduletop: number;
    show_listasindex: number;
    show_breadcrumbs: number;
    show_pagenavi: number;
}

export interface PicoCategoryData {
    id: number;
    title: string;
    desc: string;
    pid: number;
    weight: number;
    link: string;
}

export interface PicoContentData {
    id: number;
    title: string;
    cat_id: number;
    weight: number;
    link: string;
}

export interface PicoIndexData {
    module: PicoModuleData;
    categories: PicoCategoryData[];
    contents: PicoContentData[];
}

export interface PicoPageData {
    id: number;
    title: string;
    content: {
        en: string;
        ja: string;
    }
}

export const PICO_CATEGORY_ID_ROOT = 65535;

class PicoContext {

    private isInitialized: boolean;
    private dirname: string;
    private showTitle: boolean;
    private database: loki;
    private module: PicoModuleData | null;
    private categories: Collection<PicoCategoryData>;
    private contents: Collection<PicoContentData>;

    constructor(dirname: string, showTitle: boolean) {
        this.isInitialized = false;
        this.dirname = dirname;
        this.showTitle = showTitle;
        this.database = new loki(`pico_${dirname}`);
        this.module = null;
        this.categories = this.database.addCollection('categories');
        this.contents = this.database.addCollection('contents');
    }

    isShowTitle(): boolean {
        return this.showTitle;
    }

    getUrl(path: string): string {
        return `/modules/${this.dirname}` + (path !== '' ? `/${path}` : '');
    }

    getModule(): PicoModuleData | null {
        return this.module;
    }

    getCategoryByPath(link: string): PicoCategoryData | null {
        return this.categories.findOne({ link: link });
    }

    getCategory(catId: number): PicoCategoryData | null {
        return this.categories.findOne({ id: catId });
    }

    getParentCategories(catId: number): PicoCategoryData[] {
        const categories: PicoCategoryData[] = [];
        while (catId !== PICO_CATEGORY_ID_ROOT) {
            const category = this.categories.findOne({ id: catId });
            if (category === null) {
                break;
            }
            categories.unshift(category);
            catId = category.pid;
        }
        return categories;
    }

    getSubCategories(catId: number): PicoCategoryData[] {
        return this.categories.chain().find({ pid: catId }).simplesort('weight').data();
    }

    getCategoryContents(catId: number): PicoContentData[] {
        return this.contents.chain().find({ cat_id: catId }).simplesort('weight').data();
    }

    getContentCategery(contentId: number): PicoCategoryData[] {
        const content = this.contents.findOne({ id: contentId });
        if (content === null) {
            return [];
        }
        return this.getParentCategories(content.cat_id);
    }

    getFirstContent(catId: number): PicoContentData | null {
        const contents = this.contents.chain().find({ cat_id: catId }).simplesort('weight').limit(1).data();
        if (contents.length !== 1) {
            return null;
        }
        return contents[0];
    }

    getContentByPath(link: string): PicoContentData | null {
        return this.contents.findOne({ link: link });
    }

    async getPage(contentId: number): Promise<PicoPageData | null> {
        let page: PicoPageData | null = null;
        try {
            const response = await axios.get(this.getUrl(`${contentId}.json`));
            page = response.data as PicoPageData;
        } catch (err) {
            // ignore
        }
        return page;
    }

    async initialize(): Promise<boolean> {
        if (!this.isInitialized) {
            try {
                const response = await axios.get(this.getUrl('index.json'));
                const index = response.data as PicoIndexData;
                this.module = index.module;
                index.categories.forEach((value) => {
                    this.categories.insert(value);
                });
                index.contents.forEach((value) => {
                    this.contents.insert(value);
                });
            } catch (err) {
                // ignore
            }
            this.isInitialized = true;
        }
        return this.isInitialized;
    }
}

export default PicoContext;