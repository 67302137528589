import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../../common/lib/PageNotFound';
import XoopsCode from '../../common/lib/XoopsCode';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import BulletinContext, { BulletinStoryData } from './BulletinContext';
import BulletinUtils from './BulletinUtils';

interface Props {
    lang: MultiLang;
    context: BulletinContext;
    story: BulletinStoryData;
    isList: boolean;
}

const BulletinStoryItem: React.FC<Props> = (props: Props) => {
    const { lang, context, story, isList } = props;
    const topic = context.getTopic(story.topic_id);
    const urlTopic = context.getTopicUrl(story.topic_id, 5, 0);
    const urlStory = context.getStoryUrl(story.id);
    if (topic === null) {
        return <PageNotFound lang={lang} />;
    }
    const { text, rchars } = BulletinUtils.getStoryText(story, isList, lang);
    const remaining = lang === 'en' ? rchars + ' characters more' : '残り' + rchars + '字';
    return <div className="item">
        <div className="itemHead">
            <span className="itemTitle">
                <Link to={urlTopic}>{Functions.mlang(topic.title, lang)}</Link>
                &nbsp;:&nbsp;
                {isList ? <Link to={urlStory}>{Functions.mlang(story.title, lang)}</Link> : Functions.mlang(story.title, lang)}
            </span>
        </div>
        <div className="itemBody">
            <div className="itemText">
                <XoopsCode lang={lang} text={text} dohtml={true} />
            </div>
            <div className="itemPermaLink">
                {rchars > 0 && <><Link to={urlStory}>{Functions.mlang('[en]Read more..[/en][ja]続きを読む[/ja]', lang)}</Link> | {remaining}</>}
            </div>
            <div className="itemInfo">
                <span className="itemPostDate">&nbsp;{Functions.mlang('[en]on[/en][ja]投稿日時:[/ja]', lang)} {story.posttime}</span>
            </div>
        </div>
        <div style={{ clear: 'both' }}></div>
        <div className="itemFoot"></div>
    </div>;
};

export default BulletinStoryItem;