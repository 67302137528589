import React from 'react';
import { Helmet } from 'react-helmet';
import BulletinRecentNews from '../bulletin/blocks/BulletinRecentNews';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import imageMainBanner from './assets/images/main-banner.png';
import LinkBannersBlock from './blocks/LinkBannersBlock';
import RssBlock from './blocks/RssBlock';
import { RssFeedSite } from './lib/RssFeed';
import RssFeedSites from './RssFeedSites';

interface Props {
    lang: MultiLang;
}

const RSS_UPDATES_INCF: RssFeedSite[] = [
    RssFeedSites.incf
];
const RSS_UPDATES_PLATFORMS: RssFeedSite[] = [
    RssFeedSites.mpdb,
    RssFeedSites.cdtdb,
    RssFeedSites.nimg
];

const TopPage: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang(Config.SITE_SLOGAN, lang) + ' - ' + Functions.mlang(Config.SITE_TITLE, lang);
    return <div>
        <Helmet><title>{title}</title></Helmet>
        <div><img src={imageMainBanner} alt="main banner" /></div>
        <div className="col2">
            <div className="leftcolumn">
                <div className="block">
                    <div className="blockContent">
                        <LinkBannersBlock lang={lang} />
                    </div>
                </div>
            </div>
            <div className="centercolumn">
                <div className="centerCcolumn">
                    <div className="block">
                        <div className="blockTitle">
                            {Functions.mlang('[en]Latest News and Topics[/en][ja]最新ニュース[/ja]', lang)}
                        </div>
                        <div className="blockContent">
                            <BulletinRecentNews lang={lang} mydirname="news" />
                        </div>
                    </div>
                </div>
                <div className="centerLcolumn">
                    <div className="block">
                        <div className="blockTitle">
                            {Functions.mlang('[en]INCF Portal Updates[/en][ja]INCF Portal 更新情報[/ja]', lang)}
                        </div>
                        <div className="blockContent">
                            <RssBlock lang={lang} sites={RSS_UPDATES_INCF} num={5} />
                        </div>
                    </div>
                </div>
                <div className="centerRcolumn">
                    <div className="block">
                        <div className="blockTitle">
                            {Functions.mlang('[en]Platform Updates[/en][ja]プラットフォーム更新情報[/ja]', lang)}
                        </div>
                        <div className="blockContent">
                            <RssBlock lang={lang} sites={RSS_UPDATES_PLATFORMS} num={5} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default TopPage;
