import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../../common/lib/PageNotFound';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import BulletinContext from './BulletinContext';
import BulletinStoryItem from './BulletinStoryItem';
import BulletinStoriesSelect from './BulletinStoriesSelect';
import BulletinStoriesPageNavi from './BulletinStoriesPageNavi';

interface Props {
    lang: MultiLang;
    context: BulletinContext;
    topicId: number;
    storyNum: number;
    start: number;
}

const BulletinStories: React.FC<Props> = (props: Props) => {
    const { lang, context, topicId, storyNum, start } = props;
    const topic = context.getTopic(topicId);
    if (topicId !== 0 && topic === null) {
        return <PageNotFound lang={lang} />;
    }
    const module = context.getModule();
    if (module === null) {
        return <PageNotFound lang={lang} />;
    }
    const titles = [Functions.siteTitle(lang), Functions.mlang(module.name, lang)];
    if (topicId !== 0 && topic !== null) {
        titles.push(Functions.mlang(topic.title, lang));
    }
    const stories = context.getStories(topicId, storyNum, start);
    return <div className="stories">
        <Helmet>
            <title>{titles.reverse().join(' - ')}</title>
        </Helmet>
        <div style={{ float: 'right' }}>|&nbsp;<Link to={context.getArchiveUrl()}>{Functions.mlang('[en]Archive[/en][ja]アーカイブ[/ja]', lang)}</Link>&nbsp;|</div>
        <div style={{ clear: 'both', height: '0px', visibility: 'hidden' }}></div>
        <BulletinStoriesSelect lang={lang} context={context} topicId={topicId} num={storyNum} />
        {stories.map((story) => {
            return <BulletinStoryItem key={story.id} lang={lang} context={context} story={story} isList={true} />
        })}
        <BulletinStoriesPageNavi lang={lang} context={context} topicId={topicId} limit={storyNum} start={start} />
    </div>;
};

export default BulletinStories;